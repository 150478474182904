export const box71 = [
    {
        model: '102010',
        name: '扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bfc4928f-f2e7-4a55-8892-15b6cfaefc4d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102012',
        name: '锁扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102012.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7a1bf732-60e2-4101-86fa-c5a847388042.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103010',
        name: '后开扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d55cd912-ee37-464f-a601-fab6ed362970.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103012',
        name: '后开带锁扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103012.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/493dbd1d-67da-4796-9ab0-2cff03b0e210.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112021',
        name: '扣底掀盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112021.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c506edeb-c430-4760-a667-63481aea85f9.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102011',
        name: '指扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102011.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/df60533a-20ca-42ac-b9d8-5a01a7086f31.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112331',
        name: '保险扣反扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112331.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6fe62bda-7a74-46fa-b989-21ef852e7440.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112330',
        name: '保险扣正扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112330.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1c3c1c4f-101b-4387-90bb-3bc5c3824ee4.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102013',
        name: '开窗扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102013.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/eae75bf5-23d7-49d9-a6f5-3df047ccffe8.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102014',
        name: '锁扣开窗扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102014.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1f22462d-6e69-47d9-bdd7-3a54a96fbf0c.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103011',
        name: '后开带指甲扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103011.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/03ecf16d-8973-4020-a4f4-5d4ccf7fe65d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103013',
        name: '后开开窗自扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103013.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3eb58e49-828b-4113-96d5-3af33b60f590.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103014',
        name: '后开开窗带锁扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103014.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a9347674-13c2-4302-a5e2-d6728840d611.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112030',
        name: '扣底喜糖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/818102a7-a3e3-4e04-b0f6-944ea6a51587.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112050',
        name: '扣底平口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/41741831-d230-4b81-884b-8088e86cbe6c.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102020',
        name: '斜角扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f2c55bc6-8e58-4a1f-9ba2-c65ed9feaf43.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102030',
        name: '内衬扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/16b157eb-b53b-49df-8a11-8b7cbfc42361.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102041',
        name: '反三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102041.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4c9739d5-d144-4cc1-9553-0d032ce2704f.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102040',
        name: '正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6057fdaa-fdf7-400a-9898-dc58024ed22e.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110021',
        name: '反扣底插锁文件盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110021.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2c849b29-2a4e-46ca-bcb1-89085a91822e.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110020',
        name: '正扣底插锁文件盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/caac774c-99d4-4fed-bcbc-b5e5e75e05bc.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112090',
        name: '圆顶丝带孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/42013e05-25cb-432d-9ff5-f7d3672276ee.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102110',
        name: '拉链扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7a253778-9782-48a3-b9f3-a666dc62d053.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112040',
        name: '屋顶盖锁口扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e0d762f7-a8e9-4506-9e4d-0061f87ae720.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112091',
        name: '圆顶丝带孔插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112091.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/49817e6b-ec38-4207-8756-f99c8e17f8a8.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112230',
        name: '心形互锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5c3d8f4f-40ec-4915-b136-6dd3f54e85d5.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112240',
        name: '折角插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17156780001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a6246077-ba01-4b0f-a1fc-fdc30b53af11.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110800',
        name: '锁扣翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110800.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/038a13ed-cafb-441c-b874-ead4857e0d58.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112041',
        name: '屋顶拉链扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17156590001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9d839750-b975-4cdc-a960-b20293e13c87.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102112',
        name: '开窗拉链扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102112.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c6a5d933-3fbd-4d5c-9283-920c48bf9b3e.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102111',
        name: '拉链插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102111.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/72d7bf71-7fb8-4bb4-992a-cebaf92405dc.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110511',
        name: '缓冲内衬扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110511.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/78121d95-005d-4d00-a9d7-53d3ebffb7b3.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102180',
        name: '异型扣底插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102180.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/70f66548-f014-4d7a-a463-e9cf69ff1009.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112550',
        name: '保险扣内衬插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112550.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8de02fb9-1081-4269-9b00-c1892c53f496.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112092',
        name: '圆顶丝带孔异型扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112092.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d146a95e-e46a-4467-a5d6-4ef1fdaa2c36.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112031',
        name: '异型扣底喜糖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112031.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2dbc357c-693a-4e59-8443-6e92d02073ad.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110130',
        name: '斜面插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6643d751-8020-4943-a829-41cfc92c233f.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110090',
        name: '上盖心形双插口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/eaa4efe5-15fd-4a34-a9fb-4b52e5c70aba.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110810',
        name: '插舌叠盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220124/17192740001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/16d60378-239e-41a6-836f-b5981d112b83.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110220',
        name: '翻盖插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110220.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5200abb9-de07-423b-b5bd-cbe65f2ed083.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110300',
        name: '折角口扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110300.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8c95ed7e-bc5f-47a6-b098-3445d46675f2.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102016',
        name: '互锁扣插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102016.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/83c4d9eb-0c35-4041-815c-72b7f2af1848.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112680',
        name: '保险扣斜面叠盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112680.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/70deafd1-37d4-4e23-a2cb-b54a460d848e.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110310',
        name: '分隔内衬扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110310.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/837e889f-b51a-424c-92ea-8297fa6ce179.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110330',
        name: '对插舌中盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110330.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/42ab2b05-47db-495b-bf1a-e8af0900c4b7.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102060',
        name: '双保险扣正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cc13cfc2-6dc5-405a-ae17-ee2637197a87.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102220',
        name: '飞机盖保险扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102220.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1ba4f3f9-9b23-4948-b5b5-a8105ae024b8.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102018',
        name: '简易锁口扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102018.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a4193d82-f297-416e-a31f-92ef48c97213.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112500',
        name: '双插锁保险扣正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112500.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/197a7438-e362-4363-9944-07f488c3a605.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110520',
        name: '上盖对半自锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110520.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0a336394-2393-49f2-840a-2861d63e0f66.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110270',
        name: '撕拉线吊孔插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110270.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/79c12feb-e91a-41a0-923f-502599443c21.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103060',
        name: '后开外插锁带支脚扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c9915173-a8b8-4a85-a67e-19b49e153921.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102130',
        name: '保险扣防尘扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/dbeb8dac-f94e-4d85-adf4-acdb3c16d318.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '100200',
        name: '卡扣锁底插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100200.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8a712080-4c90-4518-bb9c-944262d45188.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '100240',
        name: '别扣插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100240.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f3604ef4-56b2-4b4b-92be-6a00f91d8199.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '100230',
        name: '别扣插锁弧形锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/210170fd-0509-4c24-a763-558b04ca3632.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '100250',
        name: '别扣插锁内衬扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100250.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9f5efaca-21ba-450a-8972-a5b944c02467.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112670',
        name: '前开双层盖正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112670.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e94fec77-1f34-4ce9-a5e7-46813bfbeab8.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102140',
        name: '斜面扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102140.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9287f70c-ddff-41e7-a56a-d904a3bd73e3.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102280',
        name: '对开插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102280.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8b253c23-307c-4725-83bb-f8c83ce5bba6.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '116331',
        name: '别扣式对插扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/116331.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2bce3d04-ea81-4bb9-813e-870b30135ea5.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102070',
        name: '瓶颈卡扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220121/17133690001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fa6b8eff-930b-42bb-9d15-982326cf546a.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110890',
        name: '屋顶型扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17153690001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ecacaf7e-c23b-4dec-864c-f58c0e9bbb82.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110900',
        name: '上盖双插舌锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17967240001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1a8491eb-2c96-4ba1-befc-220bc4b2a910.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102043',
        name: '反插加固扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17968240001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f373aaba-0b06-479f-9ea3-a35919f482a7.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102170',
        name: '上扣梯形扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21180240001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ef3437f8-ac35-4df4-8629-a71fed1ccccf.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102063',
        name: '易拉保险扣加固扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21179030001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8c4385ef-a75f-40d0-8e29-c8f5813905b9.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112690',
        name: '圆型瓶颈卡扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21181370001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/48c72192-fdef-40c1-b94d-20e395af2600.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103050',
        name: '后开侧梯形扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21180220001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9061cd00-3890-4b66-8a43-6a52ba2cd1b7.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '116710',
        name: '钟楼屋顶扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220314/19131400001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fd1fe574-59c4-4126-8aef-7f6b10fb5280.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110017',
        name: '正三扣底文件盒',
        model_image: '//yun.baoxiaohe.com/render/20220707/29740660001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aff44d3c-8c03-40a3-a2be-c0a4d169802b.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110461',
        name: '纪念品包装扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220325/20053240001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2b717189-2a30-4c75-8355-6ab69ad3197a.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '109171',
        name: '双折页斜面扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21195440001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4288eb35-d365-4254-98b3-d31c7c00c31f.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110350',
        name: '旋转上盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21200710001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5672bee5-18d8-491b-a289-71c7f4540cd9.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110490',
        name: '三角锁扣对插扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21206370001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ff9f3198-ae07-4c67-9962-0e244aa5a373.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102064',
        name: '易开安全扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21211220001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/087019c1-3594-4748-b15c-f14736f27f81.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '101720',
        name: '后开暗锁扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21218150001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/082de42e-a128-4d54-a81d-82aca6fb2fe6.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103015',
        name: '后开反向扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220729/30716970001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6ac5dad3-9d39-4b4f-b742-7a6fac98db71.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110860',
        name: '对口绳锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220909/32691200001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2a5520c5-fc0f-40fe-9ec8-112e0c016099.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '114100',
        name: '撕拉展示扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220915/32921920001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ff971045-4b9b-4006-9393-10a79da63f74.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103020',
        name: '双开插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/59b4d5a6-5d34-404c-8ce1-7818e32b23ec.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c57dc8f9-ead8-41a3-b298-ca65d3455fcb.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '106111',
        name: '背板扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/131bbe44-3387-4361-a7d3-0b49fe4f83d9.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/de264b14-4da8-48c6-8cc8-b5df7ee0f982.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102692',
        name: '后开外插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/de0abe76-54e8-4fd4-ac04-37af4746c4de.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/307deef6-8584-462c-8d0b-8b84cb49660d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102113',
        name: '蝴蝶锁扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/70bbb256-dffb-45eb-88bd-189c92fb19d9.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/548483d5-4203-40be-a68a-1fd0e9e0d80b.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103017',
        name: '背板后开锁扣扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/8e3704b7-9dea-4fd1-bd8e-bc222c3c7072.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b3508cc6-2d11-4f3e-be79-e91200d5a9e7.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112310',
        name: '提手正扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112310.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b8e352aa-18c3-4cb1-810b-e2e7519a2bda.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112311',
        name: '反扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112311.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/48ebc3e6-7489-44af-8bfe-18390a29be40.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112312',
        name: '锁扣正扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112312.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5f5f34f4-7a57-4617-b4e9-b432d83b30a9.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112340',
        name: '带提手带保险扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112340.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e5d395f9-25f1-4701-8211-a16aad015d0e.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112313',
        name: '锁扣反扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112313.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f8c05536-de1b-4653-889a-c88fdb92857d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112341',
        name: '提手保险扣反扣底手提盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112341.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c84ec55b-99c8-4ad3-917f-d7c56dd30f09.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112301',
        name: '反三扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112301.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ab115c53-9c0c-4dcd-ab82-38e4193eef0c.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112300',
        name: '正三扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220122/17156880001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e423b56f-1d2f-4d80-b82f-10ca77d1529e.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112370',
        name: '屋顶提手正扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112370.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/69721664-506d-411f-86f7-29f8ec0db10e.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112470',
        name: '手提正扣平顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112470.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1ea53b17-a8d5-4221-b9a4-ab2d45eb41f8.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112471',
        name: '手提反扣平顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112471.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cc006ad5-482b-40c3-a8a1-ad01f7b08dbf.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112480',
        name: '手提正三扣平顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112480.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c07a94bb-6cdb-4b72-8226-cd2df97bf971.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112481',
        name: '手提反三扣平顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112481.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ef79ce42-c7b5-4b92-8c8d-da7425697f7a.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112371',
        name: '手提反扣屋顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112371.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7035760c-bd4d-43aa-9c2f-52451a43e098.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112380',
        name: '手提正三扣屋顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112380.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6b616549-e696-420e-839d-f59d8fbef679.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112381',
        name: '手提反三扣屋顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112381.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9e78f79f-026e-4c26-963a-25910f61f71d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112082',
        name: '开窗扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112082.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/03076f20-b880-4c48-91cb-641ff7b1d01c.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112080',
        name: '扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/82ba1afb-568d-4d1a-b121-3a76695c4421.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112081',
        name: '插锁扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112081.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3b71cbba-47e0-47ef-80c8-7fd76afe5213.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112260',
        name: '手提侧插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112260.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4d73dbd8-16a4-4491-993f-897c8aacef58.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112051',
        name: '手提扣底平口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112051.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/69cd878f-a660-4a4e-aa84-c9e1b9f23869.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112052',
        name: '手提扣底叠口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112052.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d64d8005-05ff-4425-87d7-c4a33b1f0fc0.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112390',
        name: '正扣底异型手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112390.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a99b59f7-07fa-406f-9692-6205c12bc6b4.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112391',
        name: '正扣底低孔手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112391.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/02a59281-5882-47b9-bbbe-0d25d6192826.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112392',
        name: '正扣底异型气孔手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112392.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7d6c3be5-5996-4577-942e-d342a80125e6.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112520',
        name: '叠盖内折扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112520.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fb6d2096-a7e7-4079-a05a-f1dd51b6a133.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112530',
        name: '叠盖内折插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112530.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a1268c32-79d0-4e3e-adcc-633424dcc773.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112084',
        name: '方形开窗扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112084.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cf212349-a9d0-4ff5-a5cb-5f2721db2490.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112430',
        name: '粘合口提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112430.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/66105590-4cc7-418e-84d3-b0bc3b40f7c8.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112450',
        name: '双插锁提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112450.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/82ebb60e-04a7-4921-98da-fea569df15de.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112490',
        name: '斜面插锁顶扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112490.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c4862912-2b78-4e38-9f67-63b626d130f1.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112661',
        name: '折边提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112661.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ea25767e-2f96-4077-aecc-a44aca5e664c.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112085',
        name: '异型扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112085.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/876d01ef-5ffc-4a44-8264-12428fc12d0b.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112580',
        name: '正扣底插锁两用手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220127/17278170001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/291591b4-29b5-40de-abc8-e04989ffafe4.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112570',
        name: '保险扣叠盖提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112570.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b277c043-a265-462f-81d6-99bd21e3ae91.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110230',
        name: '圆形提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/29b5a9d1-e2fc-4cef-ae17-38c0d4132ba5.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112394',
        name: '正扣底异型加厚手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112394.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/180b57f5-220e-4e43-82dd-9102fdb70183.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112451',
        name: '双插锁提手正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112451.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6669337d-6bfd-4c36-8559-1b9a792100e9.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102090',
        name: '锁角提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/83937a10-9fc5-4c31-808f-7e0f50a2a875.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112452',
        name: '开窗双插锁提手正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112452.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e7007d5a-abb1-4a53-96ce-6e9f4db30905.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110830',
        name: '侧扣提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110830.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bd9fd949-3a25-4fcb-8e5d-0e430a03d920.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112611',
        name: '保险扣对插顶扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112611.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/96c51f7c-d5f4-4bae-acd9-3dfae0c212c9.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '114061',
        name: '对角提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114061.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7f2c61fa-be40-4e7f-a44b-f3511030ebbb.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '111070',
        name: '扣底提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220127/17279350001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/58c29c2c-4131-449e-85e0-809490f04031.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110340',
        name: '弧形提手梯形扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17968630001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/78cdf5a5-e01c-40c7-b97e-035ece36dc6f.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102171',
        name: '上扣梯形提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220415/22010480001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f9f7cf0a-03b1-4931-834c-e4ab465f695d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '116790',
        name: '上盖折叠手提对称双盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21209740001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/38fababc-09b4-45d2-9bff-c4bb52f58ac2.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103090',
        name: '后开提手插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21221860001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e7f2281f-611c-4da4-906f-3232128d05bf.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112043',
        name: '屋顶盖提手扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/41267509-6535-4919-867e-ef130b34cf79.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/37ed4739-1acd-4079-91f3-f10819044290.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112810',
        name: '斜边手提正扣平顶盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/bcdec2eb-2263-4f57-9eee-c230cb5bdf98.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6ad26a08-a4ae-47b3-b4e8-f8c2fcc61866.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112053',
        name: '上盖提手扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/b5c7fa6e-c75e-4d59-89ce-f5117cbac2b1.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/47390a2a-5cc6-4748-aeca-36e1b60aefaf.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112662',
        name: '插卡式提手扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/b045c549-f0a8-44dd-bec7-8754c4d7b31c.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4f46740e-d32e-491e-bd13-300869281bbf.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112342',
        name: '保险扣扣底提手盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/2f6713bd-3eac-4b83-9f2b-0a1a744aada8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cc4a18eb-f0ae-49c2-a43b-0c76dcc25fad.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112100',
        name: '开窗扣底提手玩具盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/82d72897-be30-4724-b614-3c12392cfa1f.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b389eb2c-ca95-4cd6-a47f-ff2a50929568.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112101',
        name: '开窗扣底提手玩具盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/59f3c0fb-3ba3-4046-80b8-0436adaf17f5.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/390b56cc-db85-4aa8-9e30-502c09d573f5.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112102',
        name: '开窗扣底提手玩具盒02',
        model_image: '//yun.baoxiaohe.com/admin-materials/2192ea1b-bade-4564-b969-8ea75c80f41c.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0b931e51-d474-46c5-acc8-bd23fbf6aec2.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112103',
        name: '开窗扣底提手玩具盒03',
        model_image: '//yun.baoxiaohe.com/admin-materials/9eb5ccf9-852d-412c-960b-8e66b507d0e8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c37d3596-93c4-4ff1-9145-0950312b7568.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103100',
        name: '反向扣底无盖吊孔盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103100.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b807355d-b0ab-4e8d-b3e2-ce21c3644a62.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103200',
        name: '吊孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103200.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e0816b15-d726-47af-a27c-654ce30a725a.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103201',
        name: '吊孔开窗扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103201.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3760a605-e086-4e20-86d0-fba33e6cf65d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102051',
        name: '插锁翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102051.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/560123aa-c48e-4c80-8d8d-62c2ae01ca4a.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102050',
        name: '翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8f7efd6b-b5b2-4b3c-b6d9-0554b865b64b.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '102052',
        name: '插锁单层翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102052.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c5722480-d4f9-4d5c-a62f-357270cc1027.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110532',
        name: '穿孔挂钩扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110532.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d097bb48-d49f-4e9c-ab10-9c881a85e3ca.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110360',
        name: '防尘翼吊孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110360.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b96590f1-5e96-4155-a71a-2fbf3a4d16f2.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110380',
        name: '吊孔凹形扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110380.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4e6d50b3-4289-4028-8b8a-8dd553fbd1bc.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110361',
        name: '开窗防尘翼吊孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110361.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b32e2697-dbe2-4659-be97-a23418f445cb.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '111030',
        name: '背板吊孔正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17155880001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aa1eb849-5e97-4ba2-90a9-5a8e6225d624.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103230',
        name: '斜吊孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17153580001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4466e8f6-6759-461f-8330-81d933b539d1.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110850',
        name: '背板吊孔插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21189120001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bbd647a5-8cf0-464a-a08e-3f566093c0b4.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103016',
        name: '开槽后开反向扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/451490db-9952-4f71-8f01-6bfbb01f4f7c.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3afa4115-ab56-4ab0-bd1c-8685dde53aa8.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '116015',
        name: '屋顶盖扣底吊孔盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/362f0ec3-378e-4035-8172-70c54780052b.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f01240a3-9a27-4862-9a28-9559912152b6.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110533',
        name: '后开穿孔吊孔扣底盒 ',
        model_image: '//yun.baoxiaohe.com/admin-materials/ce1a97ef-3165-4b0a-8b4b-66f5cf34a03b.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/42e036a7-07da-4d4f-959c-cd750b09ea26.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103210',
        name: '吊孔开窗扣底盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/df1ca8ca-17ee-40c5-90bc-0c46d9466678.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c2698e80-46c9-4488-8609-5dee131bfcc8.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103211',
        name: '吊孔开窗扣底盒02',
        model_image: '//yun.baoxiaohe.com/admin-materials/63e1dac4-7d21-4c5d-9747-83b69f9f49dd.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b8884b15-184e-42b4-af9e-f674ccbd12dc.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103212',
        name: '吊孔开窗扣底盒03',
        model_image: '//yun.baoxiaohe.com/admin-materials/138d32e8-5179-4d90-954a-891ee3096dca.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/df983e0e-bad0-4b0d-af0f-7baf67c0d9f2.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '103213',
        name: '吊孔开窗扣底盒04',
        model_image: '//yun.baoxiaohe.com/admin-materials/e57db0f0-0dbd-4b37-923c-e35f10504b08.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/29937fd2-37de-45e7-a2c9-cefe5e954403.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110801',
        name: '开窗锁扣翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110801.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/80d3b235-ff91-41c4-9cf8-1727dbdfe720.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112372',
        name: '扣底锁扣屋顶盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/8217bf8d-6886-48ea-8bb8-03930ab6571f.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/144fbc33-a4de-487e-a71e-4434d60e5a4d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '151160',
        name: '扣底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17151870001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ee17b26a-d63a-4a78-9c7a-c5e7f3f917be.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '171055',
        name: '无盖扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/c4769cc3-1893-4d97-ac41-d1fa642d7574.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5fe08b74-ada9-4820-88b2-a78f4e7e2d28.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '160032',
        name: '开窗扣底天地盖',
        model_image: '//yun.baoxiaohe.com/admin-materials/14e1fac2-b651-44f2-bd08-11d4ecb9ecf8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3718fb0a-0a96-4aec-b953-dbc40909cf13.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '171495',
        name: '弧形扣底展示盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/d597a1ab-2308-411c-887c-e6c84ac62e40.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e9a4c064-9c44-4047-9e2e-9a7d60cbb76c.png',
        id: '70',
        sub_id: '71',
    }
];