import React, { useEffect, useState, useRef } from 'react';
import { Field } from 'formik';
import { connect } from 'react-redux';

// reactstrap components
import {
    Row,
    Col,
} from 'reactstrap';

import client from '../../../../feathers.js';
import axios from 'axios';
import Dropdown from '../../../Common/Dropdown.js';

const ServerSidePreviewByComponent = (props) => {
    const { role, values, userInfo, userId, productDynamicPriceId, productSuperDynamicPriceId, marginId, marginSuperId, setFieldValue, packageDetails } = props;

    const [editingIndex, setEditingIndex] = useState(0);
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [isChanged, setIsChanged] = useState(false);
    
    const [ pricing, setPricing ] = useState(0);
    const [ loadingPreview, setLoadingPreview ] = useState(false);
    const [ productData, setProductData ] = useState([]);
    const [ additionalData, setAdditionalData ] = useState([]);
    const [ previewItems, setPreviewItems ] = useState([]);
    const [ customQuantity, setCustomQuantity ] = useState();
    const [ selectedCustomSize, setSelectedCustomSize ] = useState(false);

    const [ enabledCostBreakdown, setEnabledCostBreakdown ] = useState(true);
    
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (userInfo.package) {
            if (packageDetails?.moduleRestrictions?.costBreakdown) {
                setEnabledCostBreakdown(false);
            }
        }
    }, [userInfo, packageDetails]);

    const fetchProductSpecs = () => {
        if (values.csvFile.fileUrl || values.groupProduct || values.apiSource || values.clusterLabel) {
            axios({
                method: 'post',
                url: `${client.io.io.uri}fetchProductSpecs`,
                data: {
                    userId: userId,
                    productName: (values.groupType) ? values.groupType : values.name,
                    preview: values.preview,
                    productData: values,
                },
                config: { headers: {'Content-Type': 'application/json' }}
            })
                .then((res) => {
                    setPreviewItems(res.data.data);
                    setProductData({
                        ...res.data.productData,
                        ...values,
                    });
                    if(res.data.additionalData) {
                        setAdditionalData([...res.data.additionalData]);
                    }
                    if (res.data.productData.customQuantity) {
                        setCustomQuantity(res.data.productData.customQuantity);
                    }
                    setFieldValue('values', []);
                    setLoadingPreview(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        fetchProductSpecs();
    }, [userId, values._id, values.clusteredProducts, values.csvFile.fileUrl, values.groupProduct, values.apiType, values.quantityComponents,
        values.productionComponents, values.artworkComponents,  values.marginName, values.marginSuperName, values.dynamicPriceName, values.superDynamicPriceId]);

    const getCustomQuantity = (componentId) => {
        if(userId && componentId) {
            return client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res)=>{
                    if(res.data.quantity.isCustom) {
                        setCustomQuantity({
                            enabled: true,
                            min: 0,
                            max: 0,
                            type: 'custom'
                        });
                    } else {
                        setCustomQuantity({
                            type: 'minMax',
                            min: res.data.quantity.min,
                            max: (res.data.quantity.isInfinite) ? 0 : res.data.quantity.max,
                            isInfinite: res.data.quantity.isInfinite,
                            enabled: true,
                        });
                    }
                });            
        }
    };

    const updatePrice = (preview) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        const previewValues = [];

        for (let i = 0; i < preview?.values?.length && i < editingIndex + 1; i++) {
            if (values?.apiSource?.includes('api') || values?.innerProduct?.apiSource?.includes('api')) {
                if (preview.values[i] && preview.values[i] !== 'Please Select') {
                    const [name, option, value] = preview.values[i].split('~~');
                    previewValues.push({
                        name,
                        value: (value === 'undefined') ? option : `${option}~~${value}`,
                    });
                } else {
                    previewValues.push({
                        name: previewItems[i]?.title,
                        value: ''
                    });
                }

            } else {
                if (preview.values[i] && preview.values[i] !== 'Please Select') {
                    const [name, option, value, componentType, _id] = preview.values[i].split('~~');
                    previewValues.push({
                        componentId: _id,
                        componentType,
                        name,
                        value: `${option}~~${value}~~${componentType}`,
                    });
                } else {
                    previewValues.push({
                        name: previewItems[i]?.title,
                        value: ''
                    });
                }
            }
        }
        timeoutRef.current = setTimeout(() => {
            axios({
                method: 'post',
                url: `${client.io.io.uri}changeProductSpecs`,
                data: {
                    ...values,
                    productData: {
                        // ...productData, uncommented due to when changing profit margin, the productData is not updated
                        ...values,
                        customQuantity,
                        productId: values._id,
                    },
                    type: 'component',
                    values: previewValues,
                    data: previewItems,
                    preview: {
                        ...preview,
                        productDynamicPriceId,
                        productSuperDynamicPriceId,
                        marginId,
                        marginSuperId
                    },
                    index: editingIndex,
                    value,
                    title,
                },
                config: { headers: {'Content-Type': 'application/json' }}
            })
                .then((res) => {
                    setPricing(res.data);
                    
                    if(res.data.data) {
                        setPreviewItems([...res.data.data]);
                    }
                    if(res.data.additionalData) {
                        setAdditionalData([...res.data.additionalData]);
                    }
                    setProductData(res.data.productData);

                    const newPreview = [];
                    for (let i = 0; i < res.data.data?.length; i++) {
                        if (i <= editingIndex) {
                            newPreview.push(values.preview.values[i]);
                        } else {
                            newPreview.push('');
                        }
                    }
                    setFieldValue('preview.values', newPreview);

                    setLoadingPreview(false);
                })
                .catch((err) => {
                    setLoadingPreview(false);
                    console.log(err);
                });
        }, 300);
    };

    // on change preview
    useEffect(() => {
        if(values.preview?.values?.length > 0) {
            setLoadingPreview(true);
            updatePrice(values.preview);
        }
        // Cleanup the previous timeout on re-render
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isChanged, values.clusteredProducts, values.quantity, values.quantityComponents, values.productionComponents, values.artworkComponents,
        values.marginName, values.marginSuperName, values.dynamicPriceName, values.superDynamicPriceId, values.length, values.width, values.height]);

    useEffect(() => {
        setFieldValue('preview.quantityId', values.quantityComponents[0]?.componentId);
        getCustomQuantity(values.quantityComponents[0]?.componentId);
    }, [values.quantityComponents]);

    useEffect(() => {
        setFieldValue('preview.productionTimelineComponentId', values.productionComponents[0]?.componentId);
    }, [values.productionComponents]);

    useEffect(() => {
        setFieldValue('preview.artworkId', values.artworkComponents[0]?.componentId);
    }, [values.artworkComponents]);

    const renderLoader = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            if (enabledCostBreakdown) {
                return value;
            } else {
                return <span className='xs font-italic'>Upgrade to view</span>;
            }
        }
    };

    const renderFinalPrice = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            return value;
        }
    };

    const checkCustomSizeSet = (data) => {
        if (data.includes('Custom~~Custom') || data.includes('Others')) {
            setSelectedCustomSize(true);
        } else {
            setSelectedCustomSize(false);
        }
    };

    const _renderDimentions = (item) => {
        if(productData.source.includes('packaging') || productData?.oriSource?.includes('packaging')){
            return (
                <Row className='m-0 py-2 w-100'>
                    <Col className='m-0 p-0 pr-1' md='4'>
                        <small>Length ({item?.defaultSize ?? 'mm'})</small>
                        <Field 
                            className='form-control form-control-sm form-control-alternative'
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            name={'length'}
                        />
                        {/* <small className='text-danger xs'>Min: {pricing?.productData?.minLength}</small> */}
                        {/* <small className='text-danger xs'>Enter {pricing?.productData?.minLength} to {pricing?.productData?.maxLength} only</small> */}
                    </Col>
                    <Col className='m-0 p-0 pr-1' md='4'>
                        <small>Width ({item?.defaultSize ?? 'mm'})</small>
                        <Field 
                            className='form-control form-control-sm form-control-alternative'
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            name={'width'}
                        />
                        {/* <small className='text-danger xs'>Min: {pricing?.productData?.minWidth}</small> */}
                        {/* <small className='text-danger xs'>Enter {pricing?.productData?.minWidth} to {pricing?.productData?.maxWidth} only</small> */}
                    </Col>
                    <Col className='m-0 p-0 pl-1' md='4'>
                        <small>Height ({item?.defaultSize ?? 'mm'})</small>
                        <Field 
                            className='form-control form-control-sm form-control-alternative'
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            name={'height'}
                        />
                        {/* <small className='text-danger xs'>Min: {pricing?.productData?.minHeight}</small> */}
                        {/* <small className='text-danger xs'>Enter {pricing?.productData?.minHeight} to {pricing?.productData?.maxHeight} only</small> */}
                    </Col>
                    {(!!pricing?.metadata?.materialErrorMessage) && (
                        <Col className='m-0 p-0' md='12'>
                            <small className='text-danger xs'>{pricing?.metadata?.materialErrorMessage}</small>
                            {/* <small className='text-danger xs'>Enter {pricing?.productData?.minHeight} to {pricing?.productData?.maxHeight} only</small> */}
                        </Col>
                    )}
                </Row>
            );
        }else{
            return (
                <Row className='m-0 px-0 py-2 w-100 justify-content-between'>
                    <Col className='m-0 p-0 pr-1' md='5'>
                        <small>Closed Width ({item.defaultSize ?? 'mm'})</small>
                        <Field 
                            className='form-control form-control-sm form-control-alternative'
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            name={'width'}
                        />
                        {values.source.includes('booklet') ? (
                            <>
                                {values.width < (productData.minWidth / 2) && (
                                    <small className='text-danger xs'>Must be higher than {(productData?.minWidth / 2)}</small>
                                )}
                                {values.width > (productData.maxWidth / 2) && (
                                    <small className='text-danger xs'>Must be lower than {(productData?.maxWidth / 2)}</small>
                                )}
                            </>
                        ) : (
                            <>
                                {values.width < (productData.minWidth) && (
                                    <small className='text-danger xs'>Must be higher than {(productData?.minWidth)}</small>
                                )}
                                {values.width > (productData.maxWidth) && (
                                    <small className='text-danger xs'>Must be lower than {(productData?.maxWidth)}</small>
                                )}
                            </>
                        )}
                    
                    </Col>
                    <small className='d-flex align-items-center mt-4'>x</small>
                    <Col className='m-0 p-0 pl-1' md='5'>
                        <small>Height ({item.defaultSize ?? 'mm'})</small>
                        <Field 
                            className='form-control form-control-sm form-control-alternative'
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            name={'height'}
                        />
                        {values.height < productData.minHeight && (
                            <small className='text-danger xs'>Must be higher than {productData?.minHeight}</small>
                        )}
                        {values.height > productData.maxHeight && (
                            <small className='text-danger xs'>Must be lower than {productData?.maxHeight}</small>
                        )}
                    </Col>
                </Row>
            );
        }
    };

    const renderItems = (item, index) => {  
        if(item.title === 'Quantity' && (productData?.customQuantity?.enabled || productData?.dynamicPriceName || productData?.superDynamicPriceId)) {
            return (
                <Dropdown
                    name={'quantity'}
                    setFieldValue={setFieldValue}
                    values={item.data?.map((d) => {
                        return {
                            value: Number(d),
                            display: d,
                        };
                    }
                    )}
                    callback={(data) => [
                        setFieldValue(`preview.values[${index}]`, `${item.title}~~${data}~~${item.componentType}~~${item._id}`),
                        setEditingIndex(index),
                        setIsChanged(!isChanged),
                        setValue(data.split('~~')[1]),
                        setTitle('Quantity')
                    ]}
                />
            );
        } else if (item.componentType === 'Printing Size' || ((values.apiSource?.includes('api') || values?.innerProduct?.apiSource?.includes('api')) && item.title === 'Size')) {
            return (
                <>
                    <Dropdown
                        name={`preview.values[${index}]`}
                        setFieldValue={setFieldValue}
                        values={item.data?.map((d) => {
                            if (typeof d === 'string') {
                                const [ display ] = d.split('~~');
                                return {
                                    value: `${item.title}~~${d}~~${item.componentType}~~${item._id}`,
                                    display
                                };
                            } else {
                                return {
                                    value: `${item.title}~~${d}~~${item.componentType}~~${item._id}`,
                                    display: d,
                                };
                            }
                        })}
                        callback={(data) => [checkCustomSizeSet(data), setEditingIndex(index), setIsChanged(!isChanged)]}
                    />
                    {
                        selectedCustomSize &&
                        _renderDimentions(value,item)
                    }
                </>
            );
        } 
        return (
            <Dropdown
                name={`preview.values[${index}]`}
                setFieldValue={setFieldValue}
                values={item.data?.map((d) => {
                    if (typeof d === 'string') {
                        const [ display, componentId, optionId ] = d.split('~~');
                        return {
                            value: `${item.title}~~${d}~~${item.componentType}~~${item._id ?? optionId}`,
                            display
                        };
                    } else {
                        return {
                            value: `${item.title}~~${d}~~${item.componentType}~~${item._id}`,
                            display: d,
                        };
                    }
                })}
                callback={(data) => [
                    setEditingIndex(index),
                    setIsChanged(!isChanged),
                    setValue(data.split('~~')[1]),
                    setTitle(item.title)]}
            />
        );
        
    };

    return ( 
        <>
            <div className='preview-container bg-secondary rounded py-2 mb-3 position-relative'>
                {loadingPreview && (
                    <div
                        className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 100 }}
                    >
                        <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                )}
                <Row className='m-0 px-4 pt-2 w-100'>
                    <h3 className='m-0 color-primary'>Price Calculator</h3>
                </Row>
                <Row className='m-0 px-4 py-2 w-100'>
                    <small>Preview the cost of your product here.</small>
                </Row>

                {previewItems?.map((x, i) => {
                    if (x?.data?.length > 0) {
                        return (
                            <Row key={i} className='m-0 px-4 py-2 w-100'>
                                <h5 className='d-flex align-items-center'>{x.title}</h5>
                                {renderItems(x, i)}
                            </Row>
                        );       
                    } else {
                        return (
                            <Field 
                                key={i}
                                className='form-control form-control-sm form-control-alternative'
                                type='text'
                                value={x.title}
                                name={`preview.values[${i}]`}
                                style={{ display: 'none' }}
                            />
                        );
                    }
                                
                })}

                {(productData?.customQuantity?.type === 'minMax') && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5>
                            Quantity ({productData?.customQuantity?.min} ~ {(productData?.customQuantity.isInfinite) ? '∞' : productData?.customQuantity.max})
                        </h5>
                        <Field 
                            className='form-control form-control-sm form-control-alternative'
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            max={productData?.customQuantity.isInfinite ? 9999999 : productData?.customQuantity.max}
                            min={productData?.customQuantity.min}
                            name={'quantity'}
                        />
                    </Row>
                )}

                {additionalData.map((x, i) => {
                    return (
                        <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>{x.title} - {renderLoader(values.additionalDataPrices[i])}</h5>
                            <Dropdown
                                name={`additionalDataPrices[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data?.map((d) => {
                                    return {
                                        value: d.price,
                                        display: d.title
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(values.fileStorageComponents.length > 0) && (
                    <>
                        <Row className='m-0 px-4 py-2 w-100'>
                            <h5>File Upload</h5>
                            <Col md='12' className='p-3 m-0 rounded additional-info-container'>
                                <small>Upload files here</small>
                            </Col>
                        </Row>
                        
                    </>
                )}

                {(values.customFields.length > 0) && (
                    <>
                        {values.customFields.map((x, i) => {
                            return (
                                <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                                    <h5 className='d-flex align-items-center'>{x}</h5>
                                    <Field 
                                        className='form-control form-control-sm form-control-alternative'
                                        type='text'
                                        name={`customFieldsTemp[${i}]`}
                                    />
                                </Row>
                            );
                        })}
                    </>
                )}
    
                <Row className='mt-4 m-0 px-4 py-0 w-100'>
                    <h5 className='m-0 d-flex align-items-center'>Order Total</h5>
                </Row>
                <Row className='m-0 px-4 py-0 w-100'>
                    <h1 className='d-flex align-items-center color-primary'>{renderFinalPrice((Number(pricing.price ?? 0) + values.additionalDataPrices?.reduce((a, b) => Number(a) + Number(b), 0)).toFixed(2))}</h1>
                </Row>
            </div>

            <Row className='m-0 px-4 py-3 w-100 rounded additional-info-container'>
                <h3 className='d-flex align-items-center'>Additional Info</h3>
                {!!pricing.metadata?.bindingTypePrice && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Binding cost: {renderLoader(pricing.metadata?.bindingTypePrice)}</small>
                    </Row>
                )}
                {!!pricing.metadata?.coverPcsUP && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Cover UP: {renderLoader(pricing.metadata?.coverPcsUP)}</small>
                    </Row>
                )}
                {!!pricing.metadata?.contentPcsUP && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Content UP: {renderLoader(pricing.metadata?.contentPcsUP)}</small>
                    </Row>
                )}
                {!!pricing.metadata?.coverNoSheets && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Total Cover Sheets: {renderLoader(pricing.metadata?.coverNoSheets)}</small>
                    </Row>
                )}
                {!!pricing.metadata?.contentNoSheets && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Total Content Sheets: {renderLoader(pricing.metadata?.contentNoSheets)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.marginPercentage || !!pricing?.metadata?.superMarginPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Margin Percentage: +{renderLoader((((role === 'superadmin') ? pricing.metadata?.superMarginPercentage - 1 : pricing.metadata?.marginPercentage - 1) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.discountPercentage || !!pricing?.metadata?.superDiscountPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Dynamic Price Discount: -{renderLoader((((role === 'superadmin') ? 1 - pricing?.metadata?.superDiscountPercentage: 1 - pricing.metadata?.discountPercentage) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}
                {pricing?.metadata?.marginProfiles?.map((x) => {
                    if (x && x.percentage) {
                        return (
                            <Row key={x?.name} className='m-0 w-100'>
                                <small className='d-flex align-items-center'>Profile ({x.name}): {renderLoader(x.price)} (+{renderLoader(((x.percentage - 1) * 100)?.toFixed(2))}%)</small>
                            </Row>
                        );
                    } else {
                        return null;
                    }
                })}
            </Row>
        </>
    );
};

const mapStateToProps = state => ({
    userInfo: state.role.details.user,
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ServerSidePreviewByComponent);

