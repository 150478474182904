const materialSources = [
    {
        value: 'poster',
        display: 'SC-area'
    },
    {
        value: 'loose-sheet-v2',
        display: 'SC-sheet/Booklet/Packaging'
    },
];

const printCostSources = [
    {
        value: 'poster',
        display: 'SC-area'
    },
    {
        value: 'loose-sheet-v2',
        display: 'SC-sheet/Packaging'
    },
    {
        value: 'booklet',
        display: 'SC-booklet'
    },
    {
        value: 'product',
        display: 'SC-item'
    },
];

const optionSources = [
    {
        value: 'poster',
        display: 'SC-area'
    },
    {
        value: 'loose-sheet-v2',
        display: 'SC-sheet/Booklet/Packaging'
    },
    {
        value: 'product',
        display: 'SC-item'
    },
];

const env = {
    materialSources,
    printCostSources,
    optionSources,
};

export default env;