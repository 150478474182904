export const packagingCategory = [
    { id: 70, type: 'top', name: 'Boxes', super_id: 0 },
    { id: 71, type: 'secondary', name: 'Snap Lock Bottom Box', super_id: 70 },
    { id: 72, type: 'secondary', name: 'Handle Box', super_id: 70 },
    { id: 73, type: 'secondary', name: 'Auto-Lock Bottom Box', super_id: 70 },
    { id: 74, type: 'secondary', name: 'Hanging Hole Box', super_id: 70 },
    { id: 77, type: 'secondary', name: 'Flat-Paste Box', super_id: 70 },
    { id: 78, type: 'secondary', name: 'Mailer Box', super_id: 70 },
    { id: 79, type: 'secondary', name: 'Pizza Box', super_id: 70 },
    { id: 80, type: 'secondary', name: 'Flip-Top Box', super_id: 70 },
    { id: 82, type: 'secondary', name: 'Tray Box', super_id: 70 },
    { id: 150, type: 'secondary', name: 'Double Tuck Box', super_id: 70 },
    { id: 170, type: 'top', name: 'Carton Box', super_id: 0 },
    { id: 182, type: 'secondary', name: 'Flat-Top Box', super_id: 170 },
    { id: 183, type: 'secondary', name: 'Fully Sealed Box', super_id: 170 },
    { id: 186, type: 'secondary', name: 'Others', super_id: 170 },
];