import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { updateDetails } from '../redux/actions/roleActions';
import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as PlusIcon } from '../assets/img/icons/common/plus.svg';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    InputGroupText,
    InputGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import { useModal } from '../zustand/modalStore.js';

// core components
import client from '../feathers';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import axios from 'axios';
import Edit from '../components/Outlet/Edit.js';

const validationSchema = Yup.object().shape({
});

const Profile = (props) => {
    const [ editWhatsapp, setEditWhatsapp ] = useState(false);
    const [ previewDomain, setPreviewDomain ] = useState('');
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ title_count, setTitle_count ] = useState(0);
    const [autocomplete, setAutocomplete] = useState(null);
    const [currentOutletId, setCurrentOutletId] = useState(null);
    const [outlets, setOutlets] = useState([]);

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();

    useEffect(() => {
        if(props?.userInfo?.previewDomain){
            const subdomain = props?.userInfo?.previewDomain?.split('.')?.[0];
            setPreviewDomain(subdomain);
            setIsDisabled(true);
            setTitle_count(subdomain?.length);
        }
    }, [props?.userInfo?.previewDomain]);

    const getOutletData = () => {
        client.authenticate()
            .then((auth)=>{
                return client.service('outlets').find({
                    query: {
                        userId: props.userInfo._id,
                    }
                });
            })
            .then((res) => {
                if(res.data?.length > 0){
                    setOutlets(res.data);
                }
            })
            .catch((err)=>{
                console.log(err);
            });
    };

    useEffect(() => {
        getOutletData();
    }, [props.userInfo._id]);

    const onLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = (index, setFieldValue) => {
        if (autocomplete) {
            setFieldValue(`outlets.${index}.place_id`, autocomplete.getPlace().place_id);
            setFieldValue(`outlets.${index}.adr_address`, autocomplete.getPlace().adr_address);
            setFieldValue(
                `outlets.${index}.utc_offset_minutes`,
                autocomplete.getPlace().utc_offset_minutes
            );
            setFieldValue(
                `outlets.${index}.formatted_address`,
                autocomplete.getPlace().formatted_address
            );
            setFieldValue(
                `outlets.${index}.latitude`,
                autocomplete.getPlace().geometry.location.lat()
            );
            setFieldValue(
                `outlets.${index}.longitude`,
                autocomplete.getPlace().geometry.location.lng()
            );
            setFieldValue(
                `outlets.${index}.google_map_url`,
                autocomplete.getPlace().url);
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const onSubmit = async(values) => {

        if(await checkAvailability()){

            if(!isDisabled){
                values.previewDomain = `${previewDomain}.${process.env.REACT_APP_DOMAIN_URL}`;
            }
            
            axios
                .post(`${client.io.io.uri}v1/update/printer/detail`, {
                    userId: props.userInfo._id,
                    values,
                })
                .then((res) => {
                    const userData = res.data?.userData;
                    props.updateDetails(userData);
                    
                    setIsDisabled(true);
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('User Updated');
                    modalActions.setContent(`User ${userData.username} Updated Successfully!`);
                    modalActions.setCallback(undefined);
                    window.location.reload();
                })
                .catch((err) => {
                    if(err.name === 'NotAuthenticated'){
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('');
                        modalActions.setContent('Please Sign-in to continue!');
                        modalActions.setCallback(undefined);
                    }else{
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('Something went wrong!');
                        modalActions.setContent(err.message);
                        modalActions.setCallback(undefined);
                    }
                });
        }
    };

    const onChangeDomain = (name) => {
        if (name.length <= 50) {
            const value = name.toLowerCase();
            /* eslint-disable-next-line */
          const regex = /^[0-9a-zA-Z\_]+$/;
            if (value.match(regex) || value === '') {
                setPreviewDomain(value);
                setTitle_count(value.length);
            }
        }
    };

    const checkAvailability = async () => {
        if (previewDomain.length < 3) {
            modalActions.setIsOpen(true);
            modalActions.setTitle('Invalid Domain');
            modalActions.setContent('Domain should be more than 3 characters!');
            modalActions.setCallback(undefined);
            return false;
        } else {
    
            if(!isDisabled){
                return axios
                    .get(`${client.io.io.uri}verifyDomain`, {
                        params: {
                            previewDomain,
                        },
                    })
                    .then((res) => {
                        if (res.data) {
                            modalActions.setIsOpen(true);
                            modalActions.setTitle('InValid Domain');
                            modalActions.setContent('Domain is not available!');
                            modalActions.setCallback(undefined);
                            return false;
                        } else {
                            modalActions.setIsOpen(true);
                            modalActions.setTitle('Valid Domain');
                            modalActions.setContent('Domain is available!');
                            modalActions.setCallback(undefined);
                            return true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('Error');
                        modalActions.setContent(err.message);
                        modalActions.setCallback(undefined);
                        return false;
                    });
            }else{
                return true;
            }
        }
    };

    const handleDeleteOutlet = (value) => {
        if(window.confirm(`Are you sure to remove ${value.name}?`)){
            client.authenticate()
                .then((auth)=>{
                    return client.service('outlets').remove(value._id);
                })
                .then((res) => {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Outlet removed');
                    modalActions.setContent(`Outlet ${res.name} has been successfully removed.`);
                    modalActions.setCallback(undefined);
                    window.location.reload();
                })
                .catch((err)=>{
                    console.log(err);
                });
        }else{
        }
    };
    
    return (
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            {/* Page content */}
            <Container className='mt-4' fluid>
                <Formik
                    enableReinitialize
                    initialValues={{
                        shopName: props.userInfo.shopName || '',
                        isRemoveBranding: props.userInfo.isRemoveBranding || false,
                        whatsapp_receiver: props.userInfo.whatsapp_receiver || '',
                        whatsapp_sender: props.userInfo.whatsapp_sender || '',
                        custom_domain_url: props.userInfo.custom_domain_url || '',
                        custom_whatsapp_key: props.userInfo.custom_whatsapp_key || '',
                        custom_whatsapp_secret: props.userInfo.custom_whatsapp_secret || '',
                        outlets: outlets
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            setFieldValue,
                            handleChange,
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup className='d-flex'>
                                            <label
                                                className='form-control-label w-50 mb-0'
                                            >
                                                WebCal URL
                                            </label>
                                            <div className='w-100'>
                                                <InputGroup className='form-control-alternative'>
                                                    <InputGroupText style={{
                                                        padding: '0 4px',
                                                        height: 36,
                                                        borderTopRightRadius: 0,
                                                        borderBottomRightRadius: 0
                                                    }}>https://</InputGroupText>
                                                    <Input
                                                        className='form-control-alternative'
                                                        value={previewDomain}
                                                        onChange={(e) => onChangeDomain(e.target.value.trim())}
                                                        placeholder='alpahapress'
                                                        type='text'
                                                        disabled={isDisabled}
                                                    />
                                                    <InputGroupText
                                                        style={{
                                                            padding: '0 8px',
                                                            height: 36,
                                                            borderTopLeftRadius: 0,
                                                            borderBottomLeftRadius: 0
                                                        }}
                                                    >.{process.env.REACT_APP_DOMAIN_URL}</InputGroupText>
                                                </InputGroup>
                                                <p className='mb-0 text-xs mr-2 text-right'>{title_count || 0}/50</p>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg='4'>
                                        <Button
                                            color='primary' 
                                            type='button'
                                            onClick={checkAvailability}
                                        >
                                            checkAvailability
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup className='d-flex align-items-center'>
                                            <label
                                                className='form-control-label w-50 mb-0'
                                                htmlFor='shopName'
                                            >
                                                Shop Name
                                            </label>
                                            <Input
                                                className='form-control-alternative'
                                                id='shopName'
                                                value={values.shopName}
                                                onChange={handleChange}
                                                placeholder='Alpahapress Print Shop'
                                                type='text'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        <FormGroup className='d-flex align-items-center'>
                                            <label
                                                className='form-control-label w-50 mb-0'
                                                htmlFor='isRemoveBranding'
                                            >
                                                Remove PriceCal Branding
                                            </label>
                                            <Field
                                                type='checkbox' 
                                                name='isRemoveBranding' 
                                                value={values.isRemoveBranding} 
                                                checked={values.isRemoveBranding}
                                                onClick={() => setFieldValue('isRemoveBranding', !values.isRemoveBranding)}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup className='d-flex align-items-center'>
                                            <label
                                                className='form-control-label w-50 mb-0'
                                                htmlFor='whatsapp_receiver'
                                            >
                                                WhatsApp Receiver
                                            </label>
                                            <Input
                                                className='form-control-alternative'
                                                id='whatsapp_receiver'
                                                value={values.whatsapp_receiver}
                                                onChange={handleChange}
                                                placeholder='+601110899091 (Printer WhatsApp)'
                                                type='text'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        {/* <FormGroup>
                                            <label
                                                className='form-control-label'
                                                htmlFor='firstName'
                                            >
                                                First name
                                            </label>
                                            <Input
                                                className='form-control-alternative'
                                                id='firstName'
                                                value={values.firstName}
                                                onChange={handleChange}
                                                placeholder='First name'
                                                type='text'
                                            />
                                        </FormGroup> */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup className='d-flex align-items-center'>
                                            <label
                                                className='form-control-label w-50 mb-0'
                                                htmlFor='whatsapp_sender'
                                            >
                                                WhatsApp Sender
                                            </label>
                                            <Input
                                                className='form-control-alternative'
                                                id='whatsapp_sender'
                                                value={values.whatsapp_sender}
                                                onChange={handleChange}
                                                placeholder='+601110899091 (Default WhatsApp)'
                                                type='text'
                                                disabled={!editWhatsapp}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        <Button
                                            color='primary' 
                                            type='button'
                                            disabled={editWhatsapp}
                                            onClick={() => {
                                                setEditWhatsapp(true);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup className='d-flex align-items-center'>
                                            <div className='w-50'></div>
                                            <div className='w-100'>
                                                <label
                                                    className='form-control-label mb-0 text-xs'
                                                    htmlFor='custom_whatsapp_key'
                                                >
                                                    Custom WhatsApp Api
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='custom_whatsapp_key'
                                                    value={values.custom_whatsapp_key}
                                                    onChange={handleChange}
                                                    placeholder='HVIEFHIO***********************'
                                                    type='text'
                                                    disabled={!editWhatsapp}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        <FormGroup className='d-flex align-items-center'>
                                            <div className='w-50'>
                                                <label
                                                    className='form-control-label mb-0 text-xs'
                                                    htmlFor='custom_whatsapp_api'
                                                >
                                                    Custom Domain Url (Wablas)
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='custom_whatsapp_api'
                                                    value={values.custom_whatsapp_api}
                                                    onChange={handleChange}
                                                    placeholder='https://deu.wablas.com'
                                                    type='text'
                                                    disabled={!editWhatsapp}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <hr/>

                                <Row>
                                    <Col lg='11'>
                                        <div className='d-flex'>
                                            <label
                                                className='form-control-label w-25 mb-0'
                                                htmlFor='contact_us'
                                            >
                                                Contact Us
                                            </label>
                                            <div className='w-100'>
                                                <FieldArray
                                                    name='outlets'
                                                    render={(arrayHelpers) => (
                                                        <div className='w-100'>
                                                            <div className='w-100 d-flex justify-content-end'>
                                                                {
                                                                    currentOutletId?
                                                                        <Button
                                                                            className='shadow-none m-0 d-flex'
                                                                            onClick={() => {
                                                                                setCurrentOutletId(null);
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                        :
                                                                        <>
                                                                            {
                                                                                values.outlets?.length <= 1 && 
                                                                                <Button
                                                                                    color='primary'
                                                                                    className='shadow-none rounded-circle p-1 m-0 d-flex'
                                                                                    style={{ height: 25, width: 25 }}
                                                                                    onClick={() => {
                                                                                        const nonEmptyIds = values.outlets.filter(outlet => !outlet._id);
                                                                                        if (nonEmptyIds.length === 0) {
                                                                                            if(values.outlets?.length <= 1){
                                                                                                arrayHelpers.push({
                                                                                                    name: '',
                                                                                                    address: '',
                                                                                                    contactNumber: '',
                                                                                                    whatsAppNumber: '',
                                                                                                    latitude: '',
                                                                                                    longitude: '',
                                                                                                    google_map_url: '',
                                                                                                    place_id: '',
                                                                                                    adr_address: '',
                                                                                                    utc_offset_minutes: '',
                                                                                                    formatted_address: '',
                                                                                                    email: '',
                                                                                                    website: '',
                                                                                                    monday: '',
                                                                                                    tuesday: '',
                                                                                                    wednesday: '',
                                                                                                    thursday: '',
                                                                                                    friday: '',
                                                                                                    saturday: '',
                                                                                                    sunday: '',
                                                                                                    userId: props.userInfo._id
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <PlusIcon height={15} width={15} />
                                                                                </Button>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                
                                                            {/* Render the array fields */}
                                                            {
                                                                currentOutletId?
                                                                    <>
                                                                        {
                                                                            <Edit
                                                                                index={values.outlets?.findIndex(x => x._id === currentOutletId)}
                                                                                value={values.outlets?.find(x => x._id === currentOutletId)}
                                                                                userInfo={props.userInfo}
                                                                                handleChange={handleChange}
                                                                                setFieldValue={setFieldValue}
                                                                                onLoad={onLoad}
                                                                                onPlaceChanged={onPlaceChanged}
                                                                            />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {values.outlets.map((x, i) => {
                                                                            if(x._id){
                                                                                return <div 
                                                                                    className='d-flex justify-content-between align-items-center w-50 card card-body flex-row px-2 py-1 mb-2'
                                                                                    key={i}>
                                                                                    <p className='mb-0 text-xs font-weight-normal'>{x.name}</p>
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle
                                                                                            className='btn-icon-only text-light d-flex align-items-center justify-content-center'
                                                                                            href='#pablo'
                                                                                            role='button'
                                                                                            size='sm'
                                                                                            color=''
                                                                                            onClick={(e) => e.preventDefault()}
                                                                                        >
                                                                                            <i className='fas fa-ellipsis-v' />
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu className='dropdown-menu-arrow' right>
                                                                                            <DropdownItem
                                                                                                onClick={() => {
                                                                                                    setCurrentOutletId(x._id);
                                                                                                }}
                                                                                            >
                                                                                                Edit
                                                                                            </DropdownItem>
                                                                                            <DropdownItem
                                                                                                onClick={() => {
                                                                                                    handleDeleteOutlet(x);
                                                                                                }}
                                                                                            >
                                                                                                Delete
                                                                                            </DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>;
                                                                            }else{
                                                                                return (
                                                                                    <div key={i}>
                                                                                        <Edit
                                                                                            key={i}
                                                                                            index={i}
                                                                                            value={x}
                                                                                            userInfo={props.userInfo}
                                                                                            handleChange={handleChange}
                                                                                            setFieldValue={setFieldValue}
                                                                                            onLoad={onLoad}
                                                                                            onPlaceChanged={onPlaceChanged}
                                                                                        />
                                                                                        {/* <Button
                                                                                            color='danger'
                                                                                            className='ms-2'
                                                                                            onClick={() => arrayHelpers.remove(i)} // Remove item from array
                                                                                        >
                                                                                            Remove
                                                                                        </Button> */}
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </>
                                                            }
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <hr/>

                                <div className='modal-footer'>
                                    <Button
                                        color='primary' 
                                        type='submit'
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Container>

            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallback}
            />
        </>
    );
};


const mapStateToProps = state => ({
    role: state.role.details.user?state.role.details.user.role:'',
    auth: state.role.auth,
    userId: state.role.details.user?state.role.details.user._id:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    packages: state.allPackages.data,
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
    updateDetails: updateDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);