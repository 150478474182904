export const box79 = [
    {
        model: '180011',
        name: '三角托盘披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/3cf69a40-5f90-48fe-8268-ceed14407880.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fc406b0d-90de-45b2-9df9-d323b736d974.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156210',
        name: '披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156210.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d8caf2b5-2c1d-45e4-bb7d-8374f16c4688.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156211',
        name: '开窗披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156211.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f800f7b7-0bb1-4519-b536-6b9fab77b9f9.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156212',
        name: '透气孔披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/4454961a-afe6-499d-bc89-16d1237f8dd3.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/43648f6e-10d3-4fc2-a445-4ae5459fa825.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156213',
        name: '侧边插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/ee263441-9fc0-4c42-8e89-1ba20d3af0cf.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a2c23198-e099-4753-ad87-71bf3c1e9bf1.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156214',
        name: '上盖插锁翻盖披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1b2391e9-1083-43fd-9cbc-a14b2c7187c9.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/63d8345f-bb2f-4aad-a215-5624b014eab4.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156241',
        name: '六角形披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/289b0818-aac8-4700-b9fb-0f83909b0941.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/647ba278-0166-4315-82f5-a2d364e49c72.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156290',
        name: '直角披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/9f08bada-7a6d-4c2c-bee1-b3c854eaa768.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0b882709-0c80-455d-8be6-c822b83e0fee.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156243',
        name: '侧插锁披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/3dae5949-b2cc-4695-bad0-d0f2342bd706.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/613fc3e2-d9b1-4b57-8ca4-3befa8e53e3b.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '151031',
        name: '翻盖鞋盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/ea25ea61-7670-4599-ac67-8cba9ff8041f.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5b70c1b2-c834-424f-aada-e89533df0841.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156215',
        name: ' 透气孔披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/9529feaf-39b2-46e8-b81a-3164cf7c3c23.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/af9102ac-e286-4009-b868-014cc9d7cea6.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156216',
        name: '指扣披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/4ba1d4ff-fec2-4f91-9693-fc89d390bed8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6157a193-2a47-4af9-b019-c8af07e0a6df.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156217',
        name: '开窗透气孔披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/e5684d9b-46a1-40f8-840b-132f197cb038.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7f2138f6-e3d4-4966-80c5-d8354420d897.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156240',
        name: '异型披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17957790001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ce629267-ef54-44a7-96ec-261d800f5b07.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '121010',
        name: '胶合快餐盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/beb04f96-4acf-41db-848c-c0b19056aba2.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '121040',
        name: '圆形对扣盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220623/28884240001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/291c030a-d4dd-4fa3-ac03-9a759a08fd61.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '158081',
        name: '八边形披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/90e601ac-51ca-474f-856f-f879abef0172.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2f72d3f6-d6ee-44d0-a546-f274f5f9c2b6.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '180231',
        name: '三角形披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/13c11516-4ac6-4df2-bfef-e2d8c47d01cd.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d08fdc1e-3c8f-4020-9aaa-5fedb855cf88.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '180083',
        name: '卡扣三角披萨盒 ',
        model_image: '//yun.baoxiaohe.com/admin-materials/6401b900-2093-4179-aad6-100d68fdf6ff.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/005123d1-f066-4142-929c-949651698a21.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '180232',
        name: '三角翻盖披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/92599669-ed6a-47e4-907b-96c805071678.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/64da81bc-7b0d-4829-a527-9c02abfcf388.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '180233',
        name: '插锁三角披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/75d1cc0f-aead-40f5-aaa9-ee48b884366e.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fb3afdef-11f0-45ca-980f-79a55dd22184.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '180234',
        name: '三角云朵插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/c383e985-dbb0-4385-af10-e163fbffedc2.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4fbf8241-f6a2-4a43-894e-851cd47f6a97.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156242',
        name: '开窗六角披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/76600d7c-d687-4d60-90f8-411c52b99079.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f0eb5eef-f0a5-4af1-96e3-7a8ef543068e.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156218',
        name: '翻盖披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/a313f667-0ea2-4883-b937-79e73f05f6b4.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/25289d17-6fc0-435b-85af-b0b60ad9cc84.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156219',
        name: '双折翻盖披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/7030a834-cb1d-44eb-afbc-b5bd6164b983.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ccaebb7a-32c2-44a2-8b99-9c5e970aef76.png',
        id: '70',
        sub_id: '79',
    }
];