export const box82 = [
    {
        model: '120010',
        name: '瓦楞托盘盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4622a199-b701-4270-8896-bf239e4e8dca.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120011',
        name: '扇边盘式盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/ff227a7f-4552-4a08-b07b-8c27274df96c.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/780df748-f885-4116-be89-8f46c5a42a7c.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120020',
        name: '加厚型托盘盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/922731be-f097-4e14-bb64-0db79ab4ddb9.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120030',
        name: '插锁盘底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3c2e9d18-c3b9-44a0-9de8-0ee751e4f5bc.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120031',
        name: '堆叠扣插锁盘式盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/75345e7b-71cc-42e4-a1ca-862d1be701e2.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/86bdc287-e17c-490d-aad9-c3f325ebceb3.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120040',
        name: '隐藏式插锁底盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5884d4b0-1100-4d39-8c74-0dee7c7c1801.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120050',
        name: '互锁角盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/109c3be5-ca8a-4fcb-8807-a0b01f949882.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120060',
        name: '侧面插锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/61ee64ce-2870-41c7-ae82-c7393ffc25eb.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120061',
        name: '握手扣侧面插锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120061.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2aae2ae0-b8d0-4d86-8805-8d165dfbec98.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120070',
        name: '宽边卡位托盘盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/08228dc0-4078-4d32-b23f-d0a9dab51307.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7f499160-70be-4d96-9dde-a5af893552a5.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120080',
        name: '卡扣式盘式纸盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e67d5bfc-a88c-4f93-b784-9cb41ccfafa7.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120090',
        name: '厚底盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f58795a7-f237-4854-b384-9cfa7d9b8926.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120100',
        name: '四角托盘盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120100.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9602dffa-bf7a-4fac-bc54-e766abd5e541.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120110',
        name: '梯形弧边托盘盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/88bd1665-1682-49e9-99c4-2674b2e04921.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120120',
        name: '无痕底四折边盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220121/17138750001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7d5adefd-03e8-4c96-aef2-044e7b91a706.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120130',
        name: '加厚锁底盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7295b7d5-56ad-444e-915e-b77892ca613f.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120140',
        name: '侧面插锁加厚盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120140.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/14c96370-22f8-4bf6-b239-10ee71de449e.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120141',
        name: '侧面插锁互锁扣加厚盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120141.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f5c017c5-748f-4034-afab-6c6c37ea4cb6.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120142',
        name: '侧面插锁折底加厚盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220121/17138900001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/885c3290-77e1-475b-bc6a-dd2b2e81ed3c.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120143',
        name: '侧面插锁手提盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17146660001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/682d5d86-07ca-4bea-ba37-d414e8530480.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120144',
        name: '侧面下插锁互锁扣加厚盘式盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/befcf648-9d76-4819-83d1-fef3c0a4dcf1.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9935064d-cc99-4571-84be-c59726eb94c6.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120150',
        name: '护板平装盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120150.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f571fca5-a8c2-43fc-bd0e-6f7aaa86b354.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120170',
        name: '折角盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120170.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ebadf167-e0d5-4055-8ed7-7877c3a1c79c.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120180',
        name: '双摇盖卡扣锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120180.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0c514072-cb5c-410c-ab9f-c1dceb160db7.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120190',
        name: '梯形插锁盘底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120190.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/91e1a141-da2e-4b55-a348-75991320b1a9.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120200',
        name: '弧边斜面盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120200.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/45cb9bc9-ca12-40d1-8d4c-b58274482183.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120210',
        name: '四折角弧形盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120210.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/acd7e094-9894-4134-8cd6-144811c60633.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120220',
        name: '易折叠四格盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220922/33312580001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/63cc8b36-3dcc-4409-b58d-2b86fb5e70c4.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120221',
        name: ' 四格提手盘式盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/7781ec1f-da86-4895-b2c3-98fd9a56901a.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e8617206-05d2-459f-949c-5a203914021e.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120230',
        name: '圆形翻盖盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2db3fc30-ef56-47ce-a3d2-c7afc4aafe08.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120240',
        name: '盘式扣边盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120240.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e5380833-676c-4cee-aae9-d6f5c074b183.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120250',
        name: '扣边翻盖盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17146440001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/02890fe1-5204-4417-8785-fd4399bba72a.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120260',
        name: '丝带孔免胶盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120260.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/790610ee-4530-4733-a680-cd83a1073a73.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120270',
        name: '透气孔托盘',
        model_image: '//yun.baoxiaohe.com/render/20220924/33445920001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0dd5dbb3-b5c6-4e45-a97c-1d5204cbcb7f.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120280',
        name: '异型盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120280.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b9769d3a-972a-411f-a90b-a45e5293fd2c.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120290',
        name: '手提互锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120290.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f380ce7d-ccc3-433d-80c6-6c916aedf6ef.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120300',
        name: '手提盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120300.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8f00b79f-c325-4c79-b1d2-e15ed1f47e27.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120320',
        name: '互锁扣对角插锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17146820001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f9dda07b-9119-433d-9b4c-a72abfa926ae.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120330',
        name: '侧面插锁四封角盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120330.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/69edc6f0-21d9-42bf-aee8-2ea5d5fc52b9.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120340',
        name: '简易互锁扣盘式提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120340.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/88ed6acb-62ff-447d-808e-0ad7905aab96.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120360',
        name: '四折边盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120360.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/12a8e6a2-0854-41b6-b21c-8edae9837283.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120370',
        name: '四折边折角盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120370.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9c101fb5-28bf-4bf5-b936-6c1b9d733088.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120371',
        name: '四折边互锁扣折角盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17147000001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/055a549c-9c7f-424d-8533-7eee2fe33b43.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120390',
        name: '四折卡边折角盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120390.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3670546e-0c9f-45bc-b509-4d704651f8ae.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120400',
        name: '垫底折角盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17147200001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1677f9b5-3e0f-4eb8-bec7-f7762097aef8.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120420',
        name: '无痕底侧插口盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120420.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a5bdfc2f-7f66-41f6-9dca-325fb3aca227.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120430',
        name: '对称无痕底侧插盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120430.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8f4e938a-fa12-47f2-94f1-198b8435fd6f.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120460',
        name: '双格盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120460.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ed180f49-894c-42a5-86ad-2e7af37e6202.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120470',
        name: '弧形对插盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17966300001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/37ac0318-0494-46ea-85d9-db6f4ad1bf56.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120490',
        name: '底扣式托盘盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17965740001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d250330e-4e6c-4ef6-a900-6bab3f6009ee.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120510',
        name: '锯齿片盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21181700001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/dcfd3317-2f0a-4576-a40e-3a2bfd7f7a4c.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120530',
        name: '锁底堆码托盘',
        model_image: '//yun.baoxiaohe.com/render/20220224/18098040001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cb647116-5e9c-4eb8-855d-d8a542185c62.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120550',
        name: '三格梯形盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220523/25763750001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0eb8766d-55ec-4ece-8027-196b768f1c8d.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120620',
        name: '单片折叠插锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120620.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8db09470-546e-4a2c-a57f-846fb26719bf.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120660',
        name: '双耳朵卡纸盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17157290001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c894fed2-399b-4eec-b257-8fd73d74325b.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120680',
        name: '互锁扣隔卡插锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21182310001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/73936102-12d6-407b-95de-92b9f96e3d2d.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120710',
        name: '内衬平粘插锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21182810001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9e12fc6f-90e2-4f0a-a896-27896ffaeb5c.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120730',
        name: '四折边互锁底盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21183620001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a8c393a4-c5af-4f25-8c70-d6e4db55b5dc.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120810',
        name: '圆形锁扣折角盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120810.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5272996f-15e1-4fc3-a647-603b344c85c3.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121010',
        name: '胶合快餐盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/beb04f96-4acf-41db-848c-c0b19056aba2.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121020',
        name: '胶合快餐托盘盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5ba9d2d3-9026-4f00-9945-8a9b6db52466.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121030',
        name: '六点胶合快餐盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21184340001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5449b399-04d3-4f0f-a03e-7641104fd6ad.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121040',
        name: '圆形对扣盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220623/28884240001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/291c030a-d4dd-4fa3-ac03-9a759a08fd61.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121050',
        name: '无粘对盖手提盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17972740001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5be2ed46-2b4c-4b61-b544-e36cbeb13243.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121060',
        name: '上盖对锁汉堡盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fde85da0-15f0-4c4d-8d39-eb98a2dda264.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121070',
        name: '盘式蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121070.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e4cb83ba-3fcc-44c3-b7ba-408de80eca77.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121080',
        name: '手提盘式蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fc034d14-33cc-4a7a-8be7-bedc1c6849ce.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121081',
        name: '握手扣手提盘式蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121081.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/109209a2-2534-4acd-b615-c6f94e36a1cd.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121090',
        name: '梯形折角提手盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7ca8759d-c2bf-413e-9810-8dfff448de5f.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121100',
        name: '梯形自锁提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121100.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/48fb6be0-ef6b-497a-8125-5bc1043ece69.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '121130',
        name: '食品打包盒',
        model_image: '//yun.baoxiaohe.com/render/20220811/31328660001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0ca90345-59ce-484d-b191-42545b3c6567.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123020',
        name: '前挡板倾斜盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17157270001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/70b1901f-3e6d-4c9f-98ad-3d33d37f4b06.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123030',
        name: '弧形锁扣盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/123030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aced4102-f7cf-4b4c-9035-1b865db8e7c5.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123040',
        name: '反折式盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220429/23423460001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b59d2d14-8b22-4186-8631-a00b73435283.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123050',
        name: '锁底六边形盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/123050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9f20e481-edcc-40ae-986e-7110ef046fe1.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123051',
        name: '提手盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20230223/42832900001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d297d090-ace1-423a-9706-4f9998166c44.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123060',
        name: '梯形体盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/123060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fa8d5e54-b1b1-4b1f-802e-63be5da6975e.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123070',
        name: '正六边形盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/123070.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/81f4c999-2678-4992-a491-60a4afb05af5.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123090',
        name: '八角插锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/123090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a181f48d-0f26-421c-bd15-d8468824a4dc.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123130',
        name: '八边形手提盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/123130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4b17148d-6f83-4ec0-9c3d-1683a22db67f.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123140',
        name: '八边形折角提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220124/17194070001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f3a71bf7-6ae3-4c90-85cc-5af67813ea23.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123160',
        name: '六格盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/123160.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/24efb411-f8fa-497d-9df8-a9d5b49bb1c7.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123180',
        name: '异形六边托盘',
        model_image: '//yun.baoxiaohe.com/render/20220222/17973270001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3af6f996-9b8f-4b86-b8cd-830a20a14c2d.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '123210',
        name: '撕拉口盘式锁扣盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17973570001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a94044e1-42f8-4645-b96a-a52b31be286f.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '124010',
        name: '中间对插盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220124/17194440001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/63925048-51f9-425f-9f7a-b73dfa09434a.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128010',
        name: '缓冲墙盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/128010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e9765d0f-1e56-4f6d-88fd-acb469581b49.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128011',
        name: '单边加宽缓冲墙盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/97657fc1-1e1a-4bdb-83b8-52909574a0fc.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4a289e64-ac9e-401b-93dc-61efa0db61d2.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128013',
        name: '缓冲墙内罩盒',
        model_image: '//yun.baoxiaohe.com/render/20220429/23426250001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e3e09333-c44d-46bc-b43b-62b6e393728e.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128023',
        name: '四格宽边缓冲盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220713/30041110001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/59d8a3ab-5559-4318-8243-4f6acf67c40a.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128031',
        name: '开窗折角卡纸抽屉盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/128031.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/20143065-a415-4a9a-9ce1-a588378c43a6.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128032',
        name: '双层封套折角卡纸抽屉盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/128032.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/15ff1186-6153-4a0b-9835-939bc115d363.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128040',
        name: '四折边缓冲盒',
        model_image: '//yun.baoxiaohe.com/render/20220415/22009990001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2a41a239-e1d0-42bb-8846-bc7b9688dc63.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128050',
        name: '插锁翻盖缓冲墙盒',
        model_image: '//yun.baoxiaohe.com/render/20220419/22407820001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fcef59c7-ae8d-4ada-afe3-95f20afbab86.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128052',
        name: '保险扣翻盖缓冲墙盒',
        model_image: '//yun.baoxiaohe.com/render/20220419/22407350001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/51da5f10-5f50-44c8-b8c2-b26d301bf735.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128100',
        name: '梯形四折边盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220418/22297110001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a814c85e-4ee3-4d58-9aef-0072352810f5.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '128220',
        name: '双开对拉抽屉盒',
        model_image: '//yun.baoxiaohe.com/render/20221024/35223190001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/577f3ee5-a1d6-40b4-90bd-32edbb6b7db1.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '153031',
        name: '免开槽插锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/d23174a8-950f-48c8-8027-2bea57e898c9.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/187edcf5-4b75-4f91-a414-11fb5093830a.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '158130',
        name: '汉堡盒',
        model_image: '//yun.baoxiaohe.com/render/20221114/36889160001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/63ac9a81-c784-47d7-964d-fcd77be3f880.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '160012',
        name: '简易胶合对盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/94abe581-6cbe-4aa6-ae6d-e631a7aba298.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e508ff06-53a7-451a-8154-e46f3de92082.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '171181',
        name: '侧插锁半封口运输箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/eeafbe5b-a23b-4b84-8681-57709ba054fa.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0038f8c8-79e8-4976-bb97-1dfed86a0c24.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120033',
        name: '堆叠式侧粘盘式盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/c34be5bd-14cf-406c-8500-e3a0c6884288.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/66e1ff5e-c49f-49e8-b294-2c7d3f326290.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '170312',
        name: '椭圆背板多功能展示盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1e886bd7-fb36-4ae9-a5a5-1c13ee1386ea.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/29086dfc-ab5b-49d2-af7f-f02c4c54b14c.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '170021',
        name: '开口展示盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/cc430f65-dd12-41d6-a692-244eda74cd17.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4aa6e635-cc65-49c5-b29b-f00eab22604c.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '171031',
        name: '四侧柱快速展示盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/13f17a56-4e01-47f0-891e-f0cfbb7d9033.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5e9ad3c3-1a5c-4462-92f3-d633887dae66.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '151052',
        name: '圆形开窗折叠翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/d91101f3-213b-4156-b597-5c625dc0c9f4.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/370b16b3-e1b9-4345-9cf8-d9e8f6a7f73f.png',
        id: '70',
        sub_id: '82',
    }
];