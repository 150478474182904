export const box80 = [
    {
        model: '101612',
        name: '插锁旋转翻盖平粘盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/647efb36-e4b1-4bf3-befa-2432113de517.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6fa1c5a7-110c-4c6c-9773-eaff6caa45eb.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '105040',
        name: '翻盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1e53d445-8003-401d-a9ff-83f99220d6f9.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105042',
        name: '内扣翻盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/72c317f0-d010-4961-b68f-f45e320495b4.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ed519450-8681-4d08-888a-ea560975721f.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '110040',
        name: '旋转翻盖插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a05de038-b8eb-471c-a390-1ed7e4b7f648.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '110041',
        name: '互锁扣旋转翻盖插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110041.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cf043ce8-a4d8-47f5-8d4d-60b25c275ba1.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '110042',
        name: '弧边旋转翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/633d4af0-b641-4930-ad9d-5380a1e1c390.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/406fb3ab-58ab-4ffb-b15c-445194971c01.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '110043',
        name: '互锁扣旋转翻盖插锁盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/92ffcf1a-f6dc-43c7-9960-0b014ef0b37f.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/de2b3389-3698-4963-bf32-e1704cd5c479.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '110044',
        name: '弧边旋转翻盖插锁盒02',
        model_image: '//yun.baoxiaohe.com/admin-materials/f96ac87e-5a1e-4f04-bfe5-774e2522ffd5.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b59bb808-6488-4582-ba77-c5ed892cd197.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '110045',
        name: '旋转翻盖插锁盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/6e8d2880-0c09-4b57-ab39-9d723718edd9.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/33c47a20-b23a-4572-8c58-4a11c6480329.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '110047',
        name: '重力供料插锁盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/bec748f6-ba81-43ec-872b-67136693377f.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d4892ea3-8b63-4177-8a00-eced9e3158d3.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '110801',
        name: '开窗锁扣翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110801.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/80d3b235-ff91-41c4-9cf8-1727dbdfe720.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '110802',
        name: '锁扣翻盖平粘底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110802.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0bb1987e-9875-49b7-a94f-b07814f41566.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '111110',
        name: '盖板开窗插锁盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/55973cf6-322e-447c-908c-bc4b437e9a3a.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6cf7710f-f3f2-45fa-8333-6ad25b13c38b.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '112045',
        name: '开窗屋顶插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/89bd5e66-33ba-42fd-b01f-afd201483c14.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/accf7994-da2b-4aaf-bb8d-9bc8093f21cd.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112280',
        name: '锁扣翻盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220127/17277900001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4be96a8a-7e46-4d9d-9d0a-d0156844bc23.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '112372',
        name: '扣底锁扣屋顶盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/8217bf8d-6886-48ea-8bb8-03930ab6571f.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/144fbc33-a4de-487e-a71e-4434d60e5a4d.png',
        id: '70',
        sub_id: '71',
    },
    {
        model: '112820',
        name: '翻盖卡扣平底盒',
        model_image: '//yun.baoxiaohe.com/render/20220517/25139670001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/67e408c9-f3ad-4a21-aa8b-2ada3673ce03.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '120230',
        name: '圆形翻盖盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2db3fc30-ef56-47ce-a3d2-c7afc4aafe08.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120250',
        name: '扣边翻盖盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17146440001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/02890fe1-5204-4417-8785-fd4399bba72a.png',
        id: '70',
        sub_id: '82',
    },
    {
        model: '120251',
        name: '扣边翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1fdfc537-2294-4d8c-b33d-aeb22fd9ac53.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2b1c26de-0e22-44ca-91f8-5130ee4915ec.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '120252',
        name: '侧扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1b82621b-6c82-49b9-b648-403bd746eb40.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c1a18e45-36d0-4a1d-8d09-9398a2596648.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '120256',
        name: '开窗侧扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/50e091a9-2ed8-4fe2-ac97-698cdb599131.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/008664db-9e66-4a56-95a2-de8420a2b955.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '120260',
        name: '丝带孔免胶盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120260.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/790610ee-4530-4733-a680-cd83a1073a73.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '128040',
        name: '四折边缓冲盒',
        model_image: '//yun.baoxiaohe.com/render/20220415/22009990001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2a41a239-e1d0-42bb-8846-bc7b9688dc63.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '150011',
        name: '飞机盒V01',
        model_image: '//yun.baoxiaohe.com/render/20220113/150011.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6a4a2d61-cd77-4e10-990a-b81f31dfe52e.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150012',
        name: '指扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150012.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/20aed197-356b-4873-a16c-66906d3286df.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150013',
        name: '保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150013.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fe8b7ada-17bf-4fba-b415-978d81cffbb9.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150014',
        name: '双保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220126/17257220001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b5495840-12dc-4dcd-8232-1bf2c4389f31.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150015',
        name: '内衬飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150015.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0f99504c-64d7-40f4-9cfe-9dbd78bef082.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150016',
        name: '斜翼提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220915/32918610001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/55698bae-bbb4-4596-b4f6-548cb0dc5999.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150017',
        name: '提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150017.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3c0df253-7f1f-4cff-a933-af4f1735c9dd.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150018',
        name: '提手保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150018.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b041c29c-ba4b-45c5-ac0c-0276a0151719.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150020',
        name: '无插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fe1641fb-f7b7-4add-a088-fe540a663606.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150021',
        name: '开窗无插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150021.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3cc58816-8e89-4de7-aa39-eae17d7c6a24.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150022',
        name: '保险扣无插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/08f053ab-eb5f-426a-b1d3-225b42916181.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/88ffb671-5786-4a4e-8d5c-2750bc13a52b.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150030',
        name: '无侧翼飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/99ed326c-5cc5-4039-98d6-8e2dea8b5983.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150031',
        name: '开窗无侧翼飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150031.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9447ca8b-c30f-4fe4-a922-192e313d0c08.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150032',
        name: '免孔无侧翼飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150032.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/dc9bc9f0-3d8e-49f6-ada4-4da40bd62b6b.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150033',
        name: '内衬无侧翼飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150033.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a08ca1f2-a63c-4e98-bdbb-e602be8b6f34.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150034',
        name: '无侧翼无痕底飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150034.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b3f1f946-cb80-4d78-b729-8f4b7c0d214d.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150035',
        name: '无痕底飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150035.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2ae0d38e-633d-4a71-b382-ca0320bc736d.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150040',
        name: '翻盖飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f0a5f282-3f65-400f-a1cf-1c223e398576.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150041',
        name: '开窗翻盖飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150041.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/875e653c-535b-4b63-b8d4-d3e8c0417454.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150050',
        name: '开窗飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1c5c9d4d-7819-4cfb-9484-b3d48d93a52e.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150060',
        name: '开窗保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6d0ef525-7919-42b7-8b07-78ea76ab9b50.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150070',
        name: '开窗提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150070.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2bdae9d7-4724-4e09-ae6f-0d0d7f04ccc0.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150080',
        name: '开窗提手保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aa7185c1-1380-4b0b-ba69-08290370cdd9.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150082',
        name: '简易折角翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/ef40c6c6-debd-4171-a63d-df0fd96841bf.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c94646ec-ef9a-4f36-afda-73fe63817447.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '150083',
        name: '折角翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/baf2efbb-aa6d-44d2-9ea9-84ecf2f49029.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/09b18041-c5e4-4546-bcfe-46831e32333a.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '150090',
        name: '开窗双保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1a84cacc-7355-430a-9ded-5cbbd2ff4fba.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150110',
        name: '折角插锁飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bdc1ee64-1e47-4b09-b495-a73d96277769.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150111',
        name: '插锁飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150111.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0ba1c98d-1d0f-4196-94ff-b540ecc73c14.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150112',
        name: '上盖插锁飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1e8207bf-c27d-4b82-95e8-d11e63964520.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d7019a40-752a-412d-8362-35606d556cbb.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150160',
        name: '挂钩飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150160.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1a430664-6c50-4132-8875-5abe708b0e00.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150161',
        name: '开窗挂钩飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150161.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e20d0fd8-1026-4a12-a492-9879aab8f099.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150162',
        name: '自带提手挂钩飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150162.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/52d0e1f7-d996-49c7-851c-073cf7256dd6.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150170',
        name: '矩形底座内衬飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150170.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d96f0a73-4c56-4d69-abfa-ec43108c66a3.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150190',
        name: '手提绳飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150190.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b52c6905-2100-4958-a4e6-861456f2a0b0.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150230',
        name: '拉链飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/11d4874d-35a3-4542-a735-80f257359a3e.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150231',
        name: '拉链插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17967680001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2a13a9f2-86eb-41f8-a8e9-8d03b5de565c.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150233',
        name: '内衬拉链飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/53b9d7a8-a49c-424b-bd87-4954dc138ffe.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ae907629-1ba0-45ec-9201-1e10b3b631a3.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150260',
        name: '提手双保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150260.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/995ac683-b1af-4e6c-aec1-34ab264a9bf1.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150280',
        name: '双插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150280.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d12e3e41-9b7d-4d20-b914-3e75709a838d.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150300',
        name: '互锁扣卡纸飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150300.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2c84b08b-91b4-4ff5-bd7d-693d0db5fce0.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150301',
        name: '开窗互锁扣卡纸飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220121/17135920001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/21a8dbf2-31bf-43a2-980a-fc6ad9f76d7e.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150330',
        name: '无痕底展示飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150330.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4780bb78-e10e-45a8-af3f-0b60b5a2d91c.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150430',
        name: '侧边固定飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21204250001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e184bde4-0231-40bd-b6df-45f733a6a020.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150440',
        name: '固定内衬飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21219990001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f3ac67a6-d00f-4ca6-a1fd-b652318e0502.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '151010',
        name: '六角翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9a15c107-796f-4c07-a2ab-458b8142e7f8.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151030',
        name: '翻盖鞋盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/803db059-d67a-474a-9d31-85d268ab8314.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151032',
        name: '斜边翻盖鞋盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/3323a9e1-e0e4-498f-8ffe-b60d00c66f11.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/af0e71a0-e25d-4377-83a0-81d933ae8bca.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '151040',
        name: '简易平装翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9fc979de-2945-400b-9742-0385fb035ba3.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151050',
        name: '折叠翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220121/17136500001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bd66cd96-612a-478e-a443-4aea80fc9f0d.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151051',
        name: '开窗折叠翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/feeb2163-5059-4cdd-b3ea-d3c0466ffece.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3fae6790-ecb7-458e-91e3-5e7627eece52.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151060',
        name: '内双插翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f995ca9d-81ad-4757-b00b-7f406ef88b2a.png',
        id: '70',
        sub_id: '150',
    },
    {
        model: '151070',
        name: '翻盖单边锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151070.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6539fb74-be83-43c3-8fc8-96eadcf8ac23.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151071',
        name: '翻盖插锁单边锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151071.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cba4e5e7-1a02-4704-8503-a30905eb7cd7.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151080',
        name: '折角双插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b21ed5c2-115e-4723-8950-726d1e0474d0.png',
        id: '70',
        sub_id: '150',
    },
    {
        model: '151081',
        name: '折角平装翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151081.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0ab7682a-3f95-4623-bfa9-98b78fb6fe27.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151090',
        name: '半封口插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5212fd3f-4911-458a-9344-2d0c47f9f5d2.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151100',
        name: '边扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220909/32691390001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ce2e4401-4d2f-43a1-aca6-79224495a449.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151110',
        name: '双开翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1a3fc703-1705-4b67-877f-3b77caba45eb.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151130',
        name: '侧插锁提手折角翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5340c705-e10e-4841-b055-28782a3eec61.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151140',
        name: '前开互锁反折卡锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21197780001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fe94834c-cdc1-48f1-8a0d-e9aeb6bd31c7.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151150',
        name: '透气孔折角翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220915/32922890001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/23711f83-15f6-4cd8-841f-b1dc2b0db94e.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151160',
        name: '扣底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17151870001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ee17b26a-d63a-4a78-9c7a-c5e7f3f917be.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151180',
        name: '梯形折角插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17152220001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a42bf020-4810-4919-9451-c5dab8652f6a.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151190',
        name: '书型折角翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17152680001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d414aacc-66d7-49ef-936a-8679f8a9d9e7.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151210',
        name: '梯形折角反插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220418/22296020001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/28d17cb0-3691-49d1-9f6d-6a3c1a2b1197.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151220',
        name: '插锁圆头翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17959700001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/87dfc0d8-6307-4710-8d7e-9537a474e94f.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151230',
        name: '简易翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20230206/41917670001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7d9bf6ff-b46c-4aa4-b569-9ba53e43a565.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151510',
        name: '握手扣折角翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/151510.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4757fa59-3d29-44de-8156-483b0da942c6.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151630',
        name: '保险扣侧插翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21210630001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f6008820-f30e-417e-99be-b306016c257c.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152010',
        name: '胶合简易翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/152010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ba80ed52-9dae-4ab3-ac67-ca7e4815f162.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152011',
        name: '拉链简易翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/fdc34767-ba3b-4b38-9a60-7d2d608d62f4.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/665d9b7a-e53e-4796-85d4-02d1788ca986.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152020',
        name: '胶合锁扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/152020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/477fa2a7-ac7b-4a1c-8959-0082e7ebc6a2.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152030',
        name: '胶合斜翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/152030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a1377ce1-1dc8-4126-b0d0-93aca64b8b65.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152031',
        name: '胶合斜锁扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/152031.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9a6c4443-51b0-4314-b8cc-df4503e8263a.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152040',
        name: '锁扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/152040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4aed85a6-926e-4920-8751-991edd9edf57.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152050',
        name: '胶合三折边翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17150060001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ad10064a-725b-46c2-9d81-153e36d33232.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152051',
        name: '胶合指扣三折边翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20230328/45144510001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/05a4a951-07b7-40f3-8e98-cc5dcae10c10.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152060',
        name: '胶合一体翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220819/31699500001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a7c11918-6004-4482-a0e1-61177ec801b6.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152080',
        name: '简易翻盖插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220126/17258350001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ce1c808d-fb45-4307-b8c8-a2578a488519.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152090',
        name: '梯形斜面折角翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17152800001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/580d002b-34ea-47fb-a623-6b6052ca018a.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152130',
        name: '双边侧卡翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/152130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2d4929ce-ebb0-4ac8-bc7b-d14f4bd83410.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152180',
        name: '边框插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220707/29748980001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0bfecab0-e8dd-4322-a6e6-6b695bc15e12.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152190',
        name: '对锁安全扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220421/22642080001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/acab2f3e-d666-429d-a1a4-c4a7993a4c7c.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '152210',
        name: '简易不粘对开翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17964190001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1bd9d2ad-5776-4c94-8114-daf4fc508b00.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153010',
        name: '握手扣插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ff5950a0-e9ef-46f1-9c47-fc041f5c1b2d.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153011',
        name: '丝带握手扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/3794ef4f-b50e-4459-8137-f9aec82fc2ed.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6a93b44b-ed94-4f40-a45a-f1f5802a39e1.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153020',
        name: '握手扣斜面翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/476b813c-1b43-4ba4-b788-4bb85dd1c8ab.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153030',
        name: '云线插锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cd8cda09-971c-453a-ad7c-455bf2406f96.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153031',
        name: '免开槽插锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/d23174a8-950f-48c8-8027-2bea57e898c9.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/187edcf5-4b75-4f91-a414-11fb5093830a.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153032',
        name: ' 加厚型卡扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/8515d250-8e66-4570-bc35-5b224d2aa6dc.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1c4930cf-1d79-40db-b9f8-1c2b888f6063.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '153040',
        name: '折叠式粘合翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a114942f-dcd3-4ae0-8787-7c6235abf486.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153070',
        name: '弧形翻盖插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153070.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/99dd791e-1a82-45b0-b6b2-466e2422bfae.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153080',
        name: '对开插锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ca5fdb6f-e2a5-43e7-b3f1-bc6a2d9c8ae3.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153110',
        name: '手提锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9f94e9f1-2f1b-4958-bd88-96266e2b3bba.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153130',
        name: '保险扣提手插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a427ec0d-d5c0-48b6-87d6-fb241e9b9623.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153140',
        name: '保险扣侧面插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220126/17259050001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1d5b718d-2042-48c3-a4ac-5da5e0aa7eb3.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153180',
        name: '简易反折卡锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153180.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e1d83d1f-fb8c-43d8-8262-102fdc1ef838.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153181',
        name: '开窗简易反折卡锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153181.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9e3908d9-753b-4743-a387-cf1682ef7531.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153182',
        name: '简易前扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/34ec5b9c-8b4f-4c62-921c-1279a716d041.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0e9f6827-ed77-4c7e-ae22-5757ac109e89.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153210',
        name: '梯形插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17152080001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/57b82cb5-1897-438f-b993-e6a5a5f8b312.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '153230',
        name: '双面梯形插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220415/22011770001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e2d3d3d8-8f9c-45b9-b19b-543c1c8f64d0.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156000',
        name: '异型飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156000.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3924435a-2a61-493f-b8ec-eab7b0ed946d.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156001',
        name: '内衬异型飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f5a4de44-9ecb-4d74-a8a2-d668fd89c2e1.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156002',
        name: '异型插锁翻盖飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156002.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4425bf9d-544d-4f2a-bd5b-e3972e15422f.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156003',
        name: '异型插锁无痕底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156003.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/66f4ce3e-fc17-43d8-8006-cc32c9e88947.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156030',
        name: '异型侧页插扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17150040001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e0e787f7-0bf4-4d64-b1c7-47ae530f3cd2.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156050',
        name: '屋顶飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21217870001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aec8c3e5-db51-4a08-ab67-97a5d44e04fd.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156060',
        name: '双层飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20221024/35223470001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7ea3d66d-869a-45fa-bb2b-eadb98281434.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156080',
        name: '内衬插锁异型飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e47b7950-dbbd-47f5-a29e-8bd3a1c249cb.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156090',
        name: '拉链异型飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/89dea3c0-1082-4c2e-a914-f17385f357a4.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156100',
        name: '异型插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156100.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b181b8b1-129b-460c-96f9-5e4f1e369c31.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156110',
        name: '双插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/85002ae8-77fb-4e3f-99a1-64c816db60e6.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156111',
        name: '保险扣双插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156111.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bf951d40-73eb-40b7-8b2e-0003c3bd23ac.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156112',
        name: '中分插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/5559a108-9398-46ef-9051-cfc2d36da235.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/22c1a1b7-626b-443c-9b13-5dcf82cb83ec.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156130',
        name: '非粘合插锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/17945bb9-3658-44fc-b270-c5b332a0d706.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156131',
        name: '非粘合侧插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156131.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3c76ba89-f459-4e9a-9693-5aa36a837dd6.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156132',
        name: '粘合插锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156132.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/645d02f5-cf50-4bc5-8b31-77ad37e8a86a.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156150',
        name: '侧面插锁锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156150.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/70c5dfb3-64d6-48bd-913d-ce440e4436e1.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156160',
        name: '插锁盘底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156160.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e38d8417-345f-4fbd-ae72-ad1817cbd4b5.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156180',
        name: '提手锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156180.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ab17f620-729d-4540-926e-4cd15c237a88.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156181',
        name: '锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156181.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/eccfdb8e-4041-4ab5-88e0-8f7ef98f9bcb.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156200',
        name: '手提插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156200.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a6749df1-aed0-46e5-b461-e8245ab2cdba.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156210',
        name: '披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156210.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d8caf2b5-2c1d-45e4-bb7d-8374f16c4688.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156211',
        name: '开窗披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156211.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f800f7b7-0bb1-4519-b536-6b9fab77b9f9.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156212',
        name: '透气孔披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/4454961a-afe6-499d-bc89-16d1237f8dd3.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/43648f6e-10d3-4fc2-a445-4ae5459fa825.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156213',
        name: '侧边插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/ee263441-9fc0-4c42-8e89-1ba20d3af0cf.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a2c23198-e099-4753-ad87-71bf3c1e9bf1.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156214',
        name: '上盖插锁翻盖披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1b2391e9-1083-43fd-9cbc-a14b2c7187c9.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/63d8345f-bb2f-4aad-a215-5624b014eab4.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156215',
        name: ' 透气孔披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/9529feaf-39b2-46e8-b81a-3164cf7c3c23.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/af9102ac-e286-4009-b868-014cc9d7cea6.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156216',
        name: '指扣披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/4ba1d4ff-fec2-4f91-9693-fc89d390bed8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6157a193-2a47-4af9-b019-c8af07e0a6df.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156217',
        name: '开窗透气孔披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/e5684d9b-46a1-40f8-840b-132f197cb038.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7f2138f6-e3d4-4966-80c5-d8354420d897.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156220',
        name: '互锁扣插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156220.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e5d9c66c-8507-43ef-bfb1-664716b6d841.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156221',
        name: '开窗互锁扣插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156221.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/383815b5-df62-4482-9d68-6f480489b9ce.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156240',
        name: '异型披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17957790001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ce629267-ef54-44a7-96ec-261d800f5b07.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156241',
        name: '六角形披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/289b0818-aac8-4700-b9fb-0f83909b0941.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/647ba278-0166-4315-82f5-a2d364e49c72.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156243',
        name: '侧插锁披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/3dae5949-b2cc-4695-bad0-d0f2342bd706.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/613fc3e2-d9b1-4b57-8ca4-3befa8e53e3b.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156250',
        name: '四折边插锁缓冲翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20230223/42832830001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3e374e71-7eb5-45c2-b5a5-c6c47c6ab4aa.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156260',
        name: '对开锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156260.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4fed924c-33c9-4611-a337-3e488b88d435.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156270',
        name: '撕拉线对开翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17152480001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7ca30717-520c-4de5-8c19-3b5338b519e5.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156280',
        name: '平装插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156280.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/06e1126c-2db8-4ef7-9115-c51c84fb26dc.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156281',
        name: '平装拉链翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156281.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/917f217a-2905-4f05-bf96-a005e2a390e2.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156290',
        name: '直角披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/9f08bada-7a6d-4c2c-bee1-b3c854eaa768.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0b882709-0c80-455d-8be6-c822b83e0fee.png',
        id: '70',
        sub_id: '79',
    },
    {
        model: '156310',
        name: '隐藏式插锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156310.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b7d63a19-d8f1-40ee-9c21-6b992dceccbe.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156330',
        name: '翻斗锁底插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156330.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a2f3ee20-c88a-4443-abd9-eab34da4d531.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156380',
        name: '简易单边锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156380.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/93347471-3b90-4512-8e57-f475a3db5683.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156410',
        name: '互锁扣插锁缓冲翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17963510001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0a9697e7-b088-477e-b8bb-585d3c37cc01.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156430',
        name: '双生插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220304/18557690001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f76cbf55-d1e7-4bf6-a8da-ce0b8c437634.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156440',
        name: '外包正向插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220308/18734650001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/43c999a5-67c8-475d-921d-3fad2c217e04.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156480',
        name: '屋顶互锁扣插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220325/20054140001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3f5bf8e5-4360-4e5e-9e87-35188dfa36c5.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '157010',
        name: '对开折边翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220420/22515580001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/46b1f63e-110b-4898-bdc5-d20c761f868c.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '157030',
        name: '三格梯形翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220523/25766090001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1770e08d-44e6-48e0-8880-7f51ea6c0e37.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '157050',
        name: '三角形翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220520/25527190001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/004b7c01-11fc-4cc5-b6e6-12a8313f741a.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '157060',
        name: '三角对开翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220524/25923880001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4ea10d82-5366-453c-b661-f590e936812e.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158010',
        name: '双开插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220708/29807880001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/baaa6b53-0ef1-4f79-a313-c14811031ac3.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158020',
        name: '对开底座插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20221024/35222930001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8357df66-94ef-4ff7-a5cd-91bb296c09a4.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158060',
        name: '六边形翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220707/29746970001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d944d064-1d5e-43c4-88cd-a17dd5f75cbd.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158070',
        name: '六边形拉链翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20221024/35224260001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cfbc524e-6f69-481d-93b3-14435ee1a76c.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158080',
        name: '八边形锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220415/22011330001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e2202b78-a860-4ae7-bce5-2bc2f12dfa87.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158090',
        name: '缓冲边框拉链翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20221024/35221720001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a438f378-3a64-4cb8-8ee4-f35ef0cc2b4f.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158130',
        name: '汉堡盒',
        model_image: '//yun.baoxiaohe.com/render/20221114/36889160001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/63ac9a81-c784-47d7-964d-fcd77be3f880.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158210',
        name: '三角对开侧插翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20221024/35224040001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4c5bb306-b6cb-47b1-af8a-ee96bc521b0d.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158230',
        name: '开窗插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/379bef2c-572d-4bb2-b296-070caa3085f8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6b2289d4-98b4-4072-8a80-424d251a2a1b.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158231',
        name: '开窗插锁全盖翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/4b376a04-20b1-454a-a86e-5db8667be406.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0f2107d3-f54b-4ed1-8968-25c8d67c5c35.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158240',
        name: '旋转翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/61df3f4e-dab7-4a3d-856c-dd2630f8b703.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b8e4ff9e-4343-49a3-b378-c6d072f218f5.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158250',
        name: '圆形插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/ba93fa1c-bb77-45a7-8bc3-dc80b3e952f3.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/722242b6-ab14-47e2-ba28-37a14ac0b13e.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '181092',
        name: '三角翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1586a329-6b69-4105-b716-dd59f22e5748.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/84b3384a-d94b-4ed8-aeda-bc068a9f7c69.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '151052',
        name: '圆形开窗折叠翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/d91101f3-213b-4156-b597-5c625dc0c9f4.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/370b16b3-e1b9-4345-9cf8-d9e8f6a7f73f.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156004',
        name: '免开槽异型插锁飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/18b8f89a-d796-4753-a75f-6d23dad4962e.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/91960546-195c-411b-9027-ed9ed5b1dec6.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '158081',
        name: '八边形披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/90e601ac-51ca-474f-856f-f879abef0172.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2f72d3f6-d6ee-44d0-a546-f274f5f9c2b6.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '180231',
        name: '三角形披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/13c11516-4ac6-4df2-bfef-e2d8c47d01cd.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d08fdc1e-3c8f-4020-9aaa-5fedb855cf88.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '180083',
        name: '卡扣三角披萨盒 ',
        model_image: '//yun.baoxiaohe.com/admin-materials/6401b900-2093-4179-aad6-100d68fdf6ff.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/005123d1-f066-4142-929c-949651698a21.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '180232',
        name: '三角翻盖披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/92599669-ed6a-47e4-907b-96c805071678.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/64da81bc-7b0d-4829-a527-9c02abfcf388.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '180233',
        name: '插锁三角披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/75d1cc0f-aead-40f5-aaa9-ee48b884366e.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fb3afdef-11f0-45ca-980f-79a55dd22184.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '180234',
        name: '三角云朵插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/c383e985-dbb0-4385-af10-e163fbffedc2.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4fbf8241-f6a2-4a43-894e-851cd47f6a97.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156242',
        name: '开窗六角披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/76600d7c-d687-4d60-90f8-411c52b99079.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f0eb5eef-f0a5-4af1-96e3-7a8ef543068e.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156218',
        name: '翻盖披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/a313f667-0ea2-4883-b937-79e73f05f6b4.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/25289d17-6fc0-435b-85af-b0b60ad9cc84.png',
        id: '70',
        sub_id: '80',
    },
    {
        model: '156219',
        name: '双折翻盖披萨盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/7030a834-cb1d-44eb-afbc-b5bd6164b983.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ccaebb7a-32c2-44a2-8b99-9c5e970aef76.png',
        id: '70',
        sub_id: '80',
    },
];