export const cartoon183 = [
    {
        model: '200030',
        name: '0203纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f84b78f6-1cce-4ad4-a818-8eb8fd9826d4.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '200031',
        name: '0203纸箱AB',
        model_image: '//yun.baoxiaohe.com/render/20220121/17137650001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6907157a-9a61-485e-bd99-6257826a1626.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '200032',
        name: '手提孔0203纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220121/17137860001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/90449ca0-3dea-4d48-bbb8-bb4a8c5f9329.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '200033',
        name: '拉链纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/4da89ef3-4b2a-4572-909c-74d3b32d9eec.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f7dd5c80-5f69-4d93-b86e-beaae5397f4b.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '200034',
        name: '正面拉链纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/6033bd40-4f66-4f62-bfe8-e1e8076e1324.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ee4a6bec-3f1f-4f5d-972d-257a0202f63a.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '200035',
        name: '内折角拉链纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/b3876c8d-b919-4682-a9c2-c5502aa75f05.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ef2d9ad9-2eeb-4738-9cf6-ea3ca1e62aea.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '200036',
        name: '全糊口0203纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/aed59245-3f5f-4f38-96fd-2de485145785.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/edd156f7-eb91-405c-b2f3-2e83876558c0.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '200130',
        name: '简易翻盖纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/95bb901b-dd1e-4c3c-a44f-4a887be76dbb.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '202010',
        name: '双保险扣纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/202010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7aab5ccd-1c8c-4d33-86b3-b3e9dda18604.png',
        id: '170',
        sub_id: '183'
    },
    {
        model: '206090',
        name: '插锁平口底纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/a7eaaaea-4fc4-4b04-8342-3e1618a8d220.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e54402b1-c0dc-4396-9681-e4ed5356f690.png',
        id: '170',
        sub_id: '183'
    }
];