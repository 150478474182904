import {box71} from './71.js';
import {box72} from './72.js';
import {box73} from './73.js';
import {box74} from './74.js';
import {box77} from './77.js';
import {box78} from './78.js';
import {box79} from './79.js';
import {box80} from './80.js';
import {box82} from './82.js';
import {box150} from './150.js';

export const box = [].concat(box71).concat(box72).concat(box73).concat(box74).concat(box77).concat(box78).concat(box79).concat(box80).concat(box82).concat(box150);