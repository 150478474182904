export const cartoon186 = [
    {
        model: '200014',
        name: '提手孔常规纸箱',
        model_image: '//yun.baoxiaohe.com/render/20230206/41917920001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e693639d-5fbd-46c9-975b-d95f89ce07b2.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200018',
        name: '分格外贴纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/58f25c5a-e305-44a6-a4b7-0b84878b2196.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/81cdcd08-9836-4b75-af24-5c1a5c1a32eb.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200019',
        name: '两侧加厚型平口纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/2f3af394-071d-4785-baab-726404045087.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0dcd9fd8-afc9-4dc4-b6c0-d28a726c846b.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200023',
        name: '外粘平口纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/6a0eaac5-27d7-4e90-ba1f-249930162604.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2a617c2b-9d09-48bf-abd9-6a8bc02f0608.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200103',
        name: '对开提手纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/49312992-c78c-4c2e-9e48-a29ad0314a60.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a711f218-0926-44fa-818d-ceea436efd59.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200201',
        name: '无盖自带格档纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200201.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f11c6378-27ea-4bca-b050-d3fbad8c9e4a.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200210',
        name: '加强型搬家箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200210.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ce2d32c0-7ae4-48b0-a693-bf1eb30915e5.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200231',
        name: '六格挡插锁纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/6b139c1b-10a6-4a79-b230-fff23c72aec3.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8bb73709-64ca-4f3e-bdf2-d9c7bfa48955.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200232',
        name: '底部插锁式纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/1dd1ab41-1dc1-4e92-a541-6a44d451b796.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/75253d57-1486-4c03-9821-fbf90a6c9b3b.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '200330',
        name: '无胶带环保纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220629/29327810001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4bbfea73-ad36-4ef0-904d-2fb2a713a46a.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '201015',
        name: '无底对盖纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/e8085a95-878d-40f5-a782-43b08d4eefb5.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/24a7871d-939d-43ab-b07e-1a1eb8bcf573.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '204030',
        name: '锁底六格内衬提手箱',
        model_image: '//yun.baoxiaohe.com/render/20221024/35223740001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e7dcd0ac-6cf2-4ab9-ae33-e4bc0f89242c.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '205011',
        name: '盘式中封纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/663452bb-8bdf-41eb-b41e-941fee89763c.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ddf52a9a-e4af-4a11-85ce-1df15258f907.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '206010',
        name: '撕拉线异型翻盖箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/206010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fdee722a-4561-40b0-9103-17ad8e934a2c.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '206080',
        name: '无粘位盘底对口箱',
        model_image: '//yun.baoxiaohe.com/render/20220715/30128880001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/671b089e-8574-416a-9062-2d412a74e933.png',
        id: '170',
        sub_id: '186'
    },
    {
        model: '206091',
        name: '平口底翻盖纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/52aa78c0-c007-476a-a89b-0082c72f4573.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d3afc26e-36a6-4c98-8c49-533fb4f403ea.png',
        id: '170',
        sub_id: '186'
    }
];