export const box74 = [
    {
        model: '100020',
        name: '吊孔反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/590b5c27-0ea4-4df5-916d-53fdb9b9a9ba.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '100023',
        name: '吊孔锁扣反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100023.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cd769f9d-27a3-41e0-b711-36d0cd8345cc.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '100021',
        name: '吊孔开窗反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100021.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fd2c3260-121a-44fb-930c-1c5e59e3df89.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '100022',
        name: '吊孔锁扣开窗反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100022.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d853a6d7-e6d8-4814-bde1-c026581cb0dd.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '100024',
        name: '吊孔指甲扣反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100024.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fd448c7d-843d-4561-ae26-054d469779d6.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '100025',
        name: '吊孔指甲扣开窗反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100025.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0379f01b-f094-412b-8325-ffc3339ab11c.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110530',
        name: '穿孔挂钩反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110530.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/04950f4a-8f0d-44c3-b2dd-7f74ba071750.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110531',
        name: '开窗穿孔挂钩反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110531.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0ee52c63-147b-4397-afaa-ecc1b097fda0.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '116280',
        name: '侧面插锁开窗吊孔盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/116280.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c4955deb-891e-4b8c-be97-57f6c92fd937.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '100027',
        name: '支脚吊孔反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100027.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/10ea24b3-d599-4b61-a183-672cd113275b.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110570',
        name: '斜吊孔直插盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17153780001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/15e605b3-46fc-432a-ad24-c73e89c443b2.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102626',
        name: '后开三角吊孔正向插锁盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/52f9d52d-fb0f-49e2-aeb4-c91399c389b1.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cc9d9257-32e3-42a4-abde-42f013b82bb0.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103100',
        name: '反向扣底无盖吊孔盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103100.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b807355d-b0ab-4e8d-b3e2-ce21c3644a62.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103200',
        name: '吊孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103200.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e0816b15-d726-47af-a27c-654ce30a725a.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103201',
        name: '吊孔开窗扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/103201.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3760a605-e086-4e20-86d0-fba33e6cf65d.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102051',
        name: '插锁翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102051.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/560123aa-c48e-4c80-8d8d-62c2ae01ca4a.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102050',
        name: '翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8f7efd6b-b5b2-4b3c-b6d9-0554b865b64b.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102052',
        name: '插锁单层翻盖扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102052.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c5722480-d4f9-4d5c-a62f-357270cc1027.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110532',
        name: '穿孔挂钩扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110532.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d097bb48-d49f-4e9c-ab10-9c881a85e3ca.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110360',
        name: '防尘翼吊孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110360.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b96590f1-5e96-4155-a71a-2fbf3a4d16f2.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110380',
        name: '吊孔凹形扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110380.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4e6d50b3-4289-4028-8b8a-8dd553fbd1bc.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110361',
        name: '开窗防尘翼吊孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110361.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b32e2697-dbe2-4659-be97-a23418f445cb.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '111030',
        name: '背板吊孔正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17155880001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aa1eb849-5e97-4ba2-90a9-5a8e6225d624.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103230',
        name: '斜吊孔扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17153580001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4466e8f6-6759-461f-8330-81d933b539d1.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110850',
        name: '背板吊孔插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21189120001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bbd647a5-8cf0-464a-a08e-3f566093c0b4.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103016',
        name: '开槽后开反向扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/451490db-9952-4f71-8f01-6bfbb01f4f7c.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3afa4115-ab56-4ab0-bd1c-8685dde53aa8.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '116015',
        name: '屋顶盖扣底吊孔盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/362f0ec3-378e-4035-8172-70c54780052b.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f01240a3-9a27-4862-9a28-9559912152b6.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110533',
        name: '后开穿孔吊孔扣底盒 ',
        model_image: '//yun.baoxiaohe.com/admin-materials/ce1a97ef-3165-4b0a-8b4b-66f5cf34a03b.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/42e036a7-07da-4d4f-959c-cd750b09ea26.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103210',
        name: '吊孔开窗扣底盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/df1ca8ca-17ee-40c5-90bc-0c46d9466678.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c2698e80-46c9-4488-8609-5dee131bfcc8.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103211',
        name: '吊孔开窗扣底盒02',
        model_image: '//yun.baoxiaohe.com/admin-materials/63e1dac4-7d21-4c5d-9747-83b69f9f49dd.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b8884b15-184e-42b4-af9e-f674ccbd12dc.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103212',
        name: '吊孔开窗扣底盒03',
        model_image: '//yun.baoxiaohe.com/admin-materials/138d32e8-5179-4d90-954a-891ee3096dca.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/df983e0e-bad0-4b0d-af0f-7baf67c0d9f2.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '103213',
        name: '吊孔开窗扣底盒04',
        model_image: '//yun.baoxiaohe.com/admin-materials/e57db0f0-0dbd-4b37-923c-e35f10504b08.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/29937fd2-37de-45e7-a2c9-cefe5e954403.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '116013',
        name: '屋顶盖粘底吊孔盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/11969b9a-15d4-466c-a532-86dc9752c028.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7ba4e950-0cee-453d-a27b-41b6fb2b1fb2.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '106030',
        name: '吊孔自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cced1a54-7d52-42c5-8ea6-653dae1e24fa.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '106031',
        name: '吊孔开窗自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106031.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f376a617-8ccd-4cd4-932f-a72fc9795e8a.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '106080',
        name: '后开防尘翼吊孔自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0f866c6c-e28d-49c1-9c80-98a163dbd74b.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110390',
        name: '吊孔凹形自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110390.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f1c82e57-6725-4f8c-8168-142341e2be58.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110391',
        name: '反折页凹形吊孔自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21199600001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6f54750b-cc70-4de2-9d67-a019e59de5d9.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '120510',
        name: '锯齿片盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21181700001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/dcfd3317-2f0a-4576-a40e-3a2bfd7f7a4c.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '111010',
        name: '展板吊孔双插盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/111010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d91cfdcc-b64f-4506-97bf-8d1cc97be262.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '101430',
        name: '吊孔飞机式插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101430.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/79471281-1fb7-4082-a8ac-be35258c9c8a.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '101431',
        name: '吊孔开窗飞机式插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101431.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a0176502-1c64-4f23-be68-18f425de1cbe.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '101432',
        name: '吊孔锁扣飞机式插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101432.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/65112166-2f7b-40eb-9ed4-d3ab8c706d75.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '101433',
        name: '吊孔锁扣开窗飞机式插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101433.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ea3357db-ce2b-48bb-9bf7-235e9ecfadb9.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102621',
        name: '后开吊孔指扣正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102621.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/57d877cd-27da-476d-9430-233f72ad92b1.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102623',
        name: '后开吊孔指甲扣开窗正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102623.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c3b5700e-13c2-4fad-9d87-12c7e75d94a8.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102624',
        name: '后开吊孔锁扣正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102624.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/68bc22b8-174e-4a9a-86e1-8bb5a26eb496.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102625',
        name: '后开带挂钩锁扣开窗正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102625.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6269224d-9255-4945-860b-3b8ecaddc685.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '101521',
        name: '后开吊孔指扣反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101521.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b6501788-77a0-4fff-a42d-527c84ed9719.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '101522',
        name: '后开吊孔锁扣反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101522.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f600a65a-142f-4ff3-9d7a-6660880753a0.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '101524',
        name: '后开吊孔锁扣开窗反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101524.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b7251cf1-f575-4695-956a-85232fa91b0c.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110050',
        name: '侧面吊孔反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/be5a628d-1e20-47e9-be9a-c9e5d64bd12a.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110051',
        name: '开窗侧面吊孔反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110051.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/adbf4ba2-2f22-42ba-924b-4517c2a4280f.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110052',
        name: '侧面吊孔正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110052.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2f88e319-0876-48a5-9d22-da5a501e6315.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102618',
        name: '后开侧面凹吊孔正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102618.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/35d84653-5730-4777-beb9-4568a5842531.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102640',
        name: '防尘翼吊孔正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102640.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/53f9060f-9a33-463e-8703-bea6f927d05a.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102660',
        name: '后开双插侧位吊扣盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102660.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8c680809-49e3-4a12-ba02-4b11f0f4d9a1.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102710',
        name: '后开开窗侧位吊孔盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102710.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fe546381-bac5-428d-a8a6-9d1c8b4fe8ac.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '110241',
        name: '侧面吊孔梯形斜面插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110241.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5588763c-f6d8-432e-a1cf-65562f1fa988.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '102627',
        name: '后开梯形斜面吊孔盒',
        model_image: '//yun.baoxiaohe.com/render/20220421/22642660001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/eb94acd0-60a9-4d50-aa55-764152ee422f.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '101526',
        name: '后开反向单飞机孔吊口盒',
        model_image: '//yun.baoxiaohe.com/render/20220421/22642220001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/23c2b6d2-5129-43f6-86c7-9fc8f26d2387.png',
        id: '70',
        sub_id: '74',
    },
    {
        model: '116014',
        name: '屋顶盖自锁地吊孔盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/a94d7e50-cbbb-4071-b4dd-803665513b70.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/403cdf88-8be4-4433-950b-dc87a5140a72.png',
        id: '70',
        sub_id: '74',
    }
];