export const box73 = [
    {
        model: '105010',
        name: '自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/16d08296-86c2-4403-bb59-4d36953ba292.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105012',
        name: '锁扣自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105012.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a0d74729-fbd3-44ef-aef9-bfb0e7a54244.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105011',
        name: '指甲扣自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105011.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7f605b74-95e3-45a0-9e37-7f1739537b10.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105030',
        name: '带内衬自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/762538f1-e6d6-42f1-bdc2-6181f1f395ea.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112010',
        name: '自锁底平口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d4187951-0d02-4db1-a0b4-2b46f1c4f3aa.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112020',
        name: '自锁底掀盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5010b28d-b36c-4ba1-9231-7c05bc0513e4.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106010',
        name: '后开自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/17f44a03-e493-42d5-bb99-99f620f5ab8f.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106011',
        name: '后开锁扣自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106011.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9dbb0347-6a88-4a3e-a743-b22f65d86c0c.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106012',
        name: '后开指扣自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106012.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a7f013a1-ce70-40d4-b1dd-6fb376fe18db.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112322',
        name: '保险扣自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112322.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c8401d43-fe44-4b26-a371-03b0e7b60737.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105013',
        name: '开窗自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105013.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b0589c23-73ce-4cfe-b6da-24d4d373e519.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105014',
        name: '锁扣开窗自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105014.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8d9882df-d41a-4541-9b2b-64784c4c8aae.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106013',
        name: '后开开窗自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106013.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/54ba5967-6f47-4a23-9a2e-593114623753.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106014',
        name: '后开锁扣开窗自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106014.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/300b53ea-3e79-48a5-bd56-c686014d43aa.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106015',
        name: '后开指扣开窗自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106015.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ed18d1e6-8990-4e89-b9e5-4dad5b0be016.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105080',
        name: '拉链自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/302cc367-ee69-4c6b-bcaf-5ae8f8e2cf62.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105016',
        name: '锁扣延自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105016.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9db8aeaa-9ab2-44dd-933d-048d3434c33a.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105017',
        name: '锁扣延开窗自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105017.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5f57cace-987f-4ceb-8734-37f69ab6dc26.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112241',
        name: '折角插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112241.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1d56c963-3ca3-49fa-bfd9-9490600089d8.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112242',
        name: '开窗折角插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220127/17277850001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0ad49722-8ee1-4b52-ae74-941c04e84f78.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105060',
        name: '异型自锁底插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/172346c8-2b53-4cd8-87e2-88460dbd93cb.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114030',
        name: '异型折盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cb2f17e9-0380-459f-bc19-77c321e248b1.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114040',
        name: '折边插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c9547d4d-67c1-4149-ba22-8b2cabf126c0.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114130',
        name: '撕拉线内衬自锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/01cdc613-30de-423d-abb1-16f0cf0bf939.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114041',
        name: '开窗折边插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114041.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d3087a36-c904-479d-9754-09427e7db67c.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '109040',
        name: '侧面拉链自锁底机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e8ef51ec-7a03-4a12-9ee0-a2de7df62c33.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '110140',
        name: '斜面自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110140.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/77691e31-2209-4206-b046-40c2104b45cb.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '109041',
        name: '侧面反向拉链自锁底机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109041.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/03a56e99-8f3d-4a5a-8c1a-7234abc504e1.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114210',
        name: '折角口自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114210.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b4f7ad71-568e-42d9-8ce8-c4e1227513df.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114180',
        name: '四折边敞口自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114180.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2ef1a601-2f74-4be6-974d-6276c279c3dd.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '102691',
        name: '后开外插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102691.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a858f298-3537-470b-941d-6d1d38ca73ca.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112060',
        name: '心形双插口自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a5eaa457-c14c-482d-b196-cccd76c8efa0.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114110',
        name: '花型锁口自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1ef0dc73-efab-4aeb-b8d0-aaea6a5b80ac.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '110271',
        name: '撕拉线吊孔插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110271.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aabbeb0d-4077-4ecf-a200-38b3de917aff.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112042',
        name: '屋顶盖锁口自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112042.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2b9581e4-8649-4711-a2ce-c9273033dc2a.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '100241',
        name: '别扣插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/100241.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6960fdde-75e1-4940-b165-07e315111513.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '102061',
        name: '双保险扣自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102061.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3df59df0-0ecb-4918-a836-709f77794a91.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '100242',
        name: '开窗别扣插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220121/17132940001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/185e3df1-5f83-406b-b6e9-b359fd4c975a.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105110',
        name: '前开斜面自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220121/17133860001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6c0a7725-8e88-4476-bc09-37a8b4f7846d.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '100243',
        name: '别扣插锁异内衬自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17153490001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3c2b7cf9-28cf-438a-bc9e-63a9512e79a8.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105090',
        name: '六格内卡自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21180480001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a24d8345-8b2a-4f16-8e84-85c9c978a47f.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106110',
        name: '背板自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220325/20052260001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c556bcc3-39a6-4086-8a33-a08e4eabe3a7.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105120',
        name: '叠盖插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21218970001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b15c13ee-db74-4977-925b-deac77da8f0e.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '116730',
        name: '自锁底圆孔手提盒',
        model_image: '//yun.baoxiaohe.com/render/20220429/23423650001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6ccc250d-6877-4cb5-8a5c-9573dd8b4bae.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '110430',
        name: '瓶装内衬自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220525/26033120001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/23932c73-d160-45ba-a1a3-f1c9e768ece9.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '110440',
        name: '斜边自锁底手提盒',
        model_image: '//yun.baoxiaohe.com/render/20220811/31327620001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a5754258-2a26-4594-a606-96053a328f2d.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '103021',
        name: '双开插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/2dbf14ca-8df3-4a36-ab31-e609565fb974.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f189f30f-f5e1-40f4-90ab-82b48147de5d.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112044',
        name: '屋顶拉链自锁底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/87bf9a2d-fa0b-4764-ad8d-152fd25d9dcb.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2924a758-d436-47d2-9e2d-ac4450357cb4.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '102281',
        name: '对开指扣插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/8d9d01b8-4f64-4cce-9a23-991c2c20e2cf.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ea626d5d-2a85-4e70-b281-0902b414b56e.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106016',
        name: '后开撕拉口自锁底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/50869c27-06bd-4b1f-9e94-59bef78b15fb.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7527a4e0-907e-410a-aa91-64abf63adc7c.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105210',
        name: '全盖自锁底撕拉线平粘盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/2de63fb8-1e82-40d1-87a1-b1e3a15b5ad8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/648ffcf6-021f-4d57-9bbc-de48cdc4c0b2.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106030',
        name: '吊孔自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cced1a54-7d52-42c5-8ea6-653dae1e24fa.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106031',
        name: '吊孔开窗自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106031.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f376a617-8ccd-4cd4-932f-a72fc9795e8a.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '106080',
        name: '后开防尘翼吊孔自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/106080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0f866c6c-e28d-49c1-9c80-98a163dbd74b.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '110390',
        name: '吊孔凹形自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110390.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f1c82e57-6725-4f8c-8168-142341e2be58.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '110391',
        name: '反折页凹形吊孔自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21199600001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6f54750b-cc70-4de2-9d67-a019e59de5d9.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105040',
        name: '翻盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/105040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1e53d445-8003-401d-a9ff-83f99220d6f9.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112045',
        name: '开窗屋顶插锁自锁底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/89bd5e66-33ba-42fd-b01f-afd201483c14.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/accf7994-da2b-4aaf-bb8d-9bc8093f21cd.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '105042',
        name: '内扣翻盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/72c317f0-d010-4961-b68f-f45e320495b4.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ed519450-8681-4d08-888a-ea560975721f.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112320',
        name: '自锁底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112320.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/21fdd718-bbad-47ae-bed7-b1748ef60310.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112395',
        name: '自锁底底孔手提箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/8597ac79-7027-4297-8aaa-eb61f9d745ca.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1e715b9d-6f8d-41ee-8c8c-839acee7a24b.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112321',
        name: '锁扣自锁底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112321.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b83ee6f6-a5ba-4ef6-925c-30c3060baecf.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112350',
        name: '带提手带保险自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112350.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/423a8dd3-2019-4f41-b571-013f4103844a.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112110',
        name: '手提翻盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b7ae8ba9-beb1-4147-b605-f160cdcecee6.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112083',
        name: '自锁底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112083.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/63f83738-54c3-4108-a874-597a376978b2.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112660',
        name: '折边提手自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112660.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e367511b-afc8-4e8b-9beb-edcb33d0ebfa.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112590',
        name: '自锁底异型手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112590.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/682a446c-1564-4938-87b6-6653e05320f9.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114060',
        name: '对角提手自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fd8ad66a-9ed2-4f33-b451-221f91a3059e.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '111080',
        name: '自锁底提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220127/17279420001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d7875a73-a5bb-4c8d-8591-538d380bc0ed.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '114070',
        name: '三角对角提手自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220418/22297460001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/24d06f15-205a-43a3-9e3e-c6221d431c93.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '112280',
        name: '锁扣翻盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220127/17277900001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4be96a8a-7e46-4d9d-9d0a-d0156844bc23.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '171494',
        name: '弧形自锁底展示盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/05dad2e7-ff44-48f9-838a-8ce848d42280.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5310d8a2-7e49-45cf-8ccf-6943e55c6190.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '171501',
        name: '斜边插锁式自锁底展示盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/b322ffef-12e1-4fbc-9104-e1e8e4718d42.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4e231f80-e087-4690-a88c-df98e2409075.png',
        id: '70',
        sub_id: '73',
    },
    {
        model: '116014',
        name: '屋顶盖自锁地吊孔盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/a94d7e50-cbbb-4071-b4dd-803665513b70.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/403cdf88-8be4-4433-950b-dc87a5140a72.png',
        id: '70',
        sub_id: '73',
    }
];