export const cartoon182 = [
    {
        model: '200010',
        name: '常规纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c3c5f189-a097-4d47-aa89-06280795a325.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200011',
        name: '0201纸箱AB面',
        model_image: '//yun.baoxiaohe.com/render/20220121/17136730001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4fd00320-3c9e-49a4-95b1-2f6fab54085a.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200012',
        name: '手提孔0201纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200012.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c25336b0-6406-48e6-a7a0-b2385b7517c0.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200015',
        name: '内折角平口纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/6c66f24f-13f2-4f34-b23d-116914ad0d69.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7b73b172-68ae-416e-8d6b-9c428665978a.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200020',
        name: '0202纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8274955c-5a40-42f5-b121-67f5018b70f1.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200021',
        name: '0202纸箱AB面',
        model_image: '//yun.baoxiaohe.com/render/20220121/17137110001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/23b67b31-f6e9-4d17-a077-f376f1767ae2.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200022',
        name: '手提孔0202纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220121/17137430001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c87933ab-51a2-4827-9b5a-ebefa4784bd7.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200100',
        name: '简易经济纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200100.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/635df642-9f6e-4ec1-8f3a-077b5526b4ba.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200101',
        name: '简单经济纸箱AB面',
        model_image: '//yun.baoxiaohe.com/render/20220113/200101.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/17ffff75-ee51-433b-876c-7d6979df93ff.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200102',
        name: '手提孔简单经济纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200102.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e1fe993a-7582-490d-8165-5ce6a74e4eab.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200104',
        name: '提手简易经济纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/9c699c2f-4681-4484-9ef9-da9931724d68.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/39658243-3cb2-405c-a5a4-a8d454a71c86.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200200',
        name: '自带格挡纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200200.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7372358f-3537-4bcd-ba91-9ce4c479effa.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200220',
        name: '平口对插纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220408/21181120001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8f862f74-7759-4609-a3e1-c2405ffb02f3.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200230',
        name: '自带六格挡纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/12a74035-2f24-4d90-8078-5319800c9186.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200250',
        name: '六格内衬自锁底平口纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220722/30427360001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/76d30d75-cd6b-4f15-b6e1-5dbc589efb95.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '200280',
        name: '翻盖插锁平口箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/200280.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5165814a-b85b-45f0-82ea-9c42a9e70ae8.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '201010',
        name: '无盖平口纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/201010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/570c64d7-1969-43e7-a5fe-2e588d51d508.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '201011',
        name: '手提孔无盖平口纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/201011.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c2a2ad3a-243f-4314-8ffc-d0f025993589.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '201012',
        name: '无底平口纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/3e07cb00-2253-4616-902b-89f16e302161.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d4452910-6a59-488b-ac02-54764c5b633a.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '201013',
        name: '无底平口纸箱AB面',
        model_image: '//yun.baoxiaohe.com/admin-materials/f59f0f9e-49f8-42b8-9767-5987acd8cb6e.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/839a6d7d-56e2-4dcb-a4b9-262d9bfb0804.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '204010',
        name: '提手常规纸箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/204010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8ecbf3e9-79cf-40be-9ad8-7810bba320b2.png',
        id: '170',
        sub_id: '182'
    },
    {
        model: '205010',
        name: '中封纸箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/9817133f-438b-44f7-b79d-8431d0eb3139.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d5ee0d1f-9a70-4fec-abd6-d1286b8aa727.png',
        id: '170',
        sub_id: '182'
    }
];