export const box72 = [
    {
        model: '112361',
        name: '带提手保险扣平口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112361.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/60d15bbc-5bee-40ba-8520-85922872dc0d.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '111611',
        name: '弧形提手蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/111611.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7910d881-f550-415f-b63e-4abc6b992f07.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '111610',
        name: '矩形提手蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/111610.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2c5b0761-1c60-4f48-afa1-4c97c074f486.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112314',
        name: '锁扣平口底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112314.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fc10af28-5388-43fc-ab38-5301d2adb134.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112510',
        name: '手提异型锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112510.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d5a97918-e92c-4741-8faf-74d7bd76a033.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '111630',
        name: '叠盖插锁提手蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/111630.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fadc8641-e2e3-41bd-9a69-5ca6ba01b193.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '110180',
        name: '折角手提蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110180.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9cbe2156-d806-46e1-9a5a-4ee08ab6638c.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112431',
        name: '粘合口提手粘底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112431.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c59ce081-5472-4913-9cbe-2ec32d4cd69f.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '116120',
        name: '梯形斜面提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220127/17278270001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7c8325c1-4216-4382-bbdd-8842b4128538.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112160',
        name: '侧面提手平口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112160.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c0d7cb0b-f703-4242-b29a-7399a3795f92.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112640',
        name: '梯形提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112640.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/77649309-0db4-400a-97b0-9700b40062d8.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '116410',
        name: '侧扣提手保险扣锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/116410.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3183a4bc-ed87-47f8-946d-37e373095433.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '116130',
        name: '封口插底提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/116130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/135a4c6a-d9f3-4fc6-8170-9f95afb32947.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112610',
        name: '保险扣对插顶提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112610.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ee3e7e17-84d5-4ea3-9ee9-a2fc53d2c8ca.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '116430',
        name: '两格提手保险扣锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/116430.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/96889d5e-410b-4c96-8364-46f4f632628a.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '116200',
        name: '互锁扣便携式盘式手提盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17156080001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b3befcbc-5d9a-4f62-9015-6ab488299952.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '116140',
        name: '折叠粘合提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17972350001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fd0e6e38-630d-4f1c-b84b-26b82dbe82b0.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '111650',
        name: '叠盖侧插锁提手蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220308/18780510001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e6acb3ae-6b74-43dc-a673-a3dbc9261a15.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112310',
        name: '提手正扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112310.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b8e352aa-18c3-4cb1-810b-e2e7519a2bda.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112311',
        name: '反扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112311.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/48ebc3e6-7489-44af-8bfe-18390a29be40.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112312',
        name: '锁扣正扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112312.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5f5f34f4-7a57-4617-b4e9-b432d83b30a9.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112340',
        name: '带提手带保险扣扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112340.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e5d395f9-25f1-4701-8211-a16aad015d0e.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112313',
        name: '锁扣反扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112313.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f8c05536-de1b-4653-889a-c88fdb92857d.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112341',
        name: '提手保险扣反扣底手提盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112341.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c84ec55b-99c8-4ad3-917f-d7c56dd30f09.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112301',
        name: '反三扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112301.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ab115c53-9c0c-4dcd-ab82-38e4193eef0c.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112300',
        name: '正三扣底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220122/17156880001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e423b56f-1d2f-4d80-b82f-10ca77d1529e.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112370',
        name: '屋顶提手正扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112370.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/69721664-506d-411f-86f7-29f8ec0db10e.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112470',
        name: '手提正扣平顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112470.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1ea53b17-a8d5-4221-b9a4-ab2d45eb41f8.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112471',
        name: '手提反扣平顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112471.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cc006ad5-482b-40c3-a8a1-ad01f7b08dbf.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112480',
        name: '手提正三扣平顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112480.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c07a94bb-6cdb-4b72-8226-cd2df97bf971.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112481',
        name: '手提反三扣平顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112481.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ef79ce42-c7b5-4b92-8c8d-da7425697f7a.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112371',
        name: '手提反扣屋顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112371.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7035760c-bd4d-43aa-9c2f-52451a43e098.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112380',
        name: '手提正三扣屋顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112380.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6b616549-e696-420e-839d-f59d8fbef679.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112381',
        name: '手提反三扣屋顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112381.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9e78f79f-026e-4c26-963a-25910f61f71d.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112082',
        name: '开窗扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112082.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/03076f20-b880-4c48-91cb-641ff7b1d01c.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112080',
        name: '扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/82ba1afb-568d-4d1a-b121-3a76695c4421.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112081',
        name: '插锁扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112081.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3b71cbba-47e0-47ef-80c8-7fd76afe5213.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112260',
        name: '手提侧插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112260.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4d73dbd8-16a4-4491-993f-897c8aacef58.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112051',
        name: '手提扣底平口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112051.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/69cd878f-a660-4a4e-aa84-c9e1b9f23869.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112052',
        name: '手提扣底叠口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112052.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d64d8005-05ff-4425-87d7-c4a33b1f0fc0.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112390',
        name: '正扣底异型手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112390.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a99b59f7-07fa-406f-9692-6205c12bc6b4.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112391',
        name: '正扣底低孔手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112391.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/02a59281-5882-47b9-bbbe-0d25d6192826.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112392',
        name: '正扣底异型气孔手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112392.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7d6c3be5-5996-4577-942e-d342a80125e6.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112520',
        name: '叠盖内折扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112520.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fb6d2096-a7e7-4079-a05a-f1dd51b6a133.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112530',
        name: '叠盖内折插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112530.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a1268c32-79d0-4e3e-adcc-633424dcc773.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112084',
        name: '方形开窗扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112084.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cf212349-a9d0-4ff5-a5cb-5f2721db2490.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112430',
        name: '粘合口提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112430.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/66105590-4cc7-418e-84d3-b0bc3b40f7c8.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112450',
        name: '双插锁提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112450.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/82ebb60e-04a7-4921-98da-fea569df15de.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112490',
        name: '斜面插锁顶扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112490.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c4862912-2b78-4e38-9f67-63b626d130f1.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112661',
        name: '折边提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112661.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ea25767e-2f96-4077-aecc-a44aca5e664c.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112085',
        name: '异型扣底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112085.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/876d01ef-5ffc-4a44-8264-12428fc12d0b.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112580',
        name: '正扣底插锁两用手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220127/17278170001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/291591b4-29b5-40de-abc8-e04989ffafe4.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112570',
        name: '保险扣叠盖提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112570.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b277c043-a265-462f-81d6-99bd21e3ae91.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '110230',
        name: '圆形提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/29b5a9d1-e2fc-4cef-ae17-38c0d4132ba5.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112394',
        name: '正扣底异型加厚手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112394.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/180b57f5-220e-4e43-82dd-9102fdb70183.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112451',
        name: '双插锁提手正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112451.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6669337d-6bfd-4c36-8559-1b9a792100e9.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '102090',
        name: '锁角提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/83937a10-9fc5-4c31-808f-7e0f50a2a875.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112452',
        name: '开窗双插锁提手正三扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112452.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e7007d5a-abb1-4a53-96ce-6e9f4db30905.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '110830',
        name: '侧扣提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110830.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bd9fd949-3a25-4fcb-8e5d-0e430a03d920.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112611',
        name: '保险扣对插顶扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112611.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/96c51f7c-d5f4-4bae-acd9-3dfae0c212c9.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '114061',
        name: '对角提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114061.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7f2c61fa-be40-4e7f-a44b-f3511030ebbb.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '111070',
        name: '扣底提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220127/17279350001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/58c29c2c-4131-449e-85e0-809490f04031.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '110340',
        name: '弧形提手梯形扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17968630001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/78cdf5a5-e01c-40c7-b97e-035ece36dc6f.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '102171',
        name: '上扣梯形提手扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220415/22010480001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f9f7cf0a-03b1-4931-834c-e4ab465f695d.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '116790',
        name: '上盖折叠手提对称双盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21209740001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/38fababc-09b4-45d2-9bff-c4bb52f58ac2.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '103090',
        name: '后开提手插锁扣底盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21221860001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e7f2281f-611c-4da4-906f-3232128d05bf.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112043',
        name: '屋顶盖提手扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/41267509-6535-4919-867e-ef130b34cf79.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/37ed4739-1acd-4079-91f3-f10819044290.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112810',
        name: '斜边手提正扣平顶盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/bcdec2eb-2263-4f57-9eee-c230cb5bdf98.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6ad26a08-a4ae-47b3-b4e8-f8c2fcc61866.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112053',
        name: '上盖提手扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/b5c7fa6e-c75e-4d59-89ce-f5117cbac2b1.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/47390a2a-5cc6-4748-aeca-36e1b60aefaf.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112662',
        name: '插卡式提手扣底盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/b045c549-f0a8-44dd-bec7-8754c4d7b31c.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4f46740e-d32e-491e-bd13-300869281bbf.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112342',
        name: '保险扣扣底提手盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/2f6713bd-3eac-4b83-9f2b-0a1a744aada8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/cc4a18eb-f0ae-49c2-a43b-0c76dcc25fad.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112100',
        name: '开窗扣底提手玩具盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/82d72897-be30-4724-b614-3c12392cfa1f.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b389eb2c-ca95-4cd6-a47f-ff2a50929568.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112101',
        name: '开窗扣底提手玩具盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/59f3c0fb-3ba3-4046-80b8-0436adaf17f5.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/390b56cc-db85-4aa8-9e30-502c09d573f5.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112102',
        name: '开窗扣底提手玩具盒02',
        model_image: '//yun.baoxiaohe.com/admin-materials/2192ea1b-bade-4564-b969-8ea75c80f41c.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0b931e51-d474-46c5-acc8-bd23fbf6aec2.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112103',
        name: '开窗扣底提手玩具盒03',
        model_image: '//yun.baoxiaohe.com/admin-materials/9eb5ccf9-852d-412c-960b-8e66b507d0e8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c37d3596-93c4-4ff1-9145-0950312b7568.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '111612',
        name: '经济锁扣提手蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/111612.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/52f0350a-61f4-4fdf-a502-db133b45f903.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '101520',
        name: '后开吊孔反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101520.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8025e4ea-f57d-4065-bf30-63d62d0dc992.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '102620',
        name: '后开吊孔正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102620.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d17f1fe9-deb4-4346-a9f6-227541f3fd0d.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '102622',
        name: '后开吊孔开窗正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/102622.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/92e96dbb-037d-487f-8f46-4bd6ca8df063.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '101523',
        name: '后开吊孔开窗反向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101523.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/85341c54-2fba-49f0-891c-c37d04a6c703.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112320',
        name: '自锁底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112320.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/21fdd718-bbad-47ae-bed7-b1748ef60310.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112395',
        name: '自锁底底孔手提箱',
        model_image: '//yun.baoxiaohe.com/admin-materials/8597ac79-7027-4297-8aaa-eb61f9d745ca.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1e715b9d-6f8d-41ee-8c8c-839acee7a24b.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '123051',
        name: '提手盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20230223/42832900001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d297d090-ace1-423a-9706-4f9998166c44.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150019',
        name: '上提手飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/bcd58455-c913-41f4-afa8-856568c020a6.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/321099db-8563-4f97-b31b-ce04960bf1e9.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150017',
        name: '提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150017.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3c0df253-7f1f-4cff-a933-af4f1735c9dd.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150018',
        name: '提手保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150018.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b041c29c-ba4b-45c5-ac0c-0276a0151719.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150190',
        name: '手提绳飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150190.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b52c6905-2100-4958-a4e6-861456f2a0b0.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150016',
        name: '斜翼提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220915/32918610001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/55698bae-bbb4-4596-b4f6-548cb0dc5999.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '121070',
        name: '盘式蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121070.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e4cb83ba-3fcc-44c3-b7ba-408de80eca77.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '123140',
        name: '八边形折角提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220124/17194070001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f3a71bf7-6ae3-4c90-85cc-5af67813ea23.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '120221',
        name: ' 四格提手盘式盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/7781ec1f-da86-4895-b2c3-98fd9a56901a.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e8617206-05d2-459f-949c-5a203914021e.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112321',
        name: '锁扣自锁底手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112321.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b83ee6f6-a5ba-4ef6-925c-30c3060baecf.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112350',
        name: '带提手带保险自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112350.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/423a8dd3-2019-4f41-b571-013f4103844a.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112110',
        name: '手提翻盖自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b7ae8ba9-beb1-4147-b605-f160cdcecee6.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112083',
        name: '自锁底蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112083.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/63f83738-54c3-4108-a874-597a376978b2.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112660',
        name: '折边提手自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112660.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e367511b-afc8-4e8b-9beb-edcb33d0ebfa.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '112590',
        name: '自锁底异型手提箱',
        model_image: '//yun.baoxiaohe.com/render/20220113/112590.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/682a446c-1564-4938-87b6-6653e05320f9.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '114060',
        name: '对角提手自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/114060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fd8ad66a-9ed2-4f33-b451-221f91a3059e.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '111080',
        name: '自锁底提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220127/17279420001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d7875a73-a5bb-4c8d-8591-538d380bc0ed.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '114070',
        name: '三角对角提手自锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220418/22297460001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/24d06f15-205a-43a3-9e3e-c6221d431c93.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '153110',
        name: '手提锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/153110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9f94e9f1-2f1b-4958-bd88-96266e2b3bba.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '156180',
        name: '提手锁底翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156180.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ab17f620-729d-4540-926e-4cd15c237a88.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '156200',
        name: '手提插锁翻盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156200.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a6749df1-aed0-46e5-b461-e8245ab2cdba.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '121080',
        name: '手提盘式蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fc034d14-33cc-4a7a-8be7-bedc1c6849ce.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '121081',
        name: '握手扣手提盘式蛋糕盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121081.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/109209a2-2534-4acd-b615-c6f94e36a1cd.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '120290',
        name: '手提互锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120290.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f380ce7d-ccc3-433d-80c6-6c916aedf6ef.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '123130',
        name: '八边形手提盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/123130.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4b17148d-6f83-4ec0-9c3d-1683a22db67f.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '121090',
        name: '梯形折角提手盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7ca8759d-c2bf-413e-9810-8dfff448de5f.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '120340',
        name: '简易互锁扣盘式提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/120340.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/88ed6acb-62ff-447d-808e-0ad7905aab96.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '121100',
        name: '梯形自锁提手盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/121100.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/48fb6be0-ef6b-497a-8125-5bc1043ece69.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '121050',
        name: '无粘对盖手提盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17972740001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5be2ed46-2b4c-4b61-b544-e36cbeb13243.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '116190',
        name: '手提圆柱分隔内衬插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17156450001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/78964a15-db45-4c99-a1bd-13a1cc78609d.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '102670',
        name: '后开侧提正向插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220415/22010660001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6d56cb39-6243-468e-9b18-b2e2513f930d.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150070',
        name: '开窗提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150070.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2bdae9d7-4724-4e09-ae6f-0d0d7f04ccc0.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150080',
        name: '开窗提手保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aa7185c1-1380-4b0b-ba69-08290370cdd9.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150260',
        name: '提手双保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150260.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/995ac683-b1af-4e6c-aec1-34ab264a9bf1.png',
        id: '70',
        sub_id: '72',
    },
    {
        model: '150162',
        name: '自带提手挂钩飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150162.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/52d0e1f7-d996-49c7-851c-073cf7256dd6.png',
        id: '70',
        sub_id: '72',
    }
];