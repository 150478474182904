export const box77 = [
    {
        model: '108001',
        name: '纸巾盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/108001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c2d828f3-2993-44ec-82a6-bf079a0ca1a9.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '101610',
        name: '后开反向插锁平口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101610.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/22bc0f37-cab7-4ee6-94c1-3b967dec7500.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '112360',
        name: '带保险扣平口盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112360.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/56c1b7af-1eb8-48f2-8aba-22cf88a2abf9.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109000',
        name: '简易机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109000.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/36cf80ae-509d-48d8-af67-0a7d7358bb0b.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '108002',
        name: '角抽纸巾盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/108002.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/105e120d-62f0-488e-8373-b2381ef38b6f.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '108020',
        name: '半圆孔纸巾盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/108020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/192e00cd-a5f2-41bf-8d24-7f6db6b789a3.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '108000',
        name: '简易纸巾盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/108000.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b4e3ed54-4349-4b11-b857-689c688a4920.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '112511',
        name: '平口异型锁底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112511.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/8f392503-a738-41e8-95bc-07b38fbca01b.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109030',
        name: '拉链管式机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a78e7fa2-0c82-44d5-a3e2-07420cce90e1.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110016',
        name: '粘底文件盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110016.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ff3e3f8a-ed87-4c3b-9c09-24cc4a105a2f.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110611',
        name: '叠盖粘底插锁盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110611.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/15c7fecc-ad9a-45a0-8763-02fa4597e644.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110160',
        name: '叠盖机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110160.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f843d734-4c34-4fbb-b027-f7f097fc0a27.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110170',
        name: '侧面撕拉线拉链盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110170.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9d8c96ad-0069-49ee-ae41-c521c2b5723d.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '108090',
        name: '插锁卡片盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/108090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fdc830ef-a40f-49d5-8306-76ba29902b9e.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110200',
        name: '魔术贴粘底叠盖盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110200.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ae020efe-6bba-4b5e-bcfd-283d16a8571a.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '116012',
        name: '开窗粘底屋顶盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/116012.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7df70b5f-ba3f-48cb-87e3-e5a856b8cac9.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '116020',
        name: '屋顶拉链粘底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/116020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d6882a75-c5f6-4ee9-913d-cc2cf8fcd856.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109060',
        name: '侧面撕拉线机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b83d59db-f0ae-4314-90ba-0845365aaadc.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109020',
        name: '弧形叠盖机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6a88016f-1c62-48c9-8a95-fc8c2dd3dfe7.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109090',
        name: '侧面撕拉线异型机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bdc0d4fb-609e-4c97-858a-a90b48960454.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110250',
        name: '隔卡插锁平粘底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/110250.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e58b419c-d34f-4f6f-9c6d-f77028efce55.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '116060',
        name: '旋转口平粘盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/116060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1e6c7cc7-ed22-43fa-bfb8-d4af8a3a87c7.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '101611',
        name: '后开反向插锁平粘底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/101611.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b573db99-e232-4cdf-89fa-bbb15af87fe7.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109170',
        name: '斜面反插锁机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109170.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f918d39a-bdc8-4788-bcf3-c2a9fd0512c0.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109050',
        name: '撕拉线机包盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/109050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5679a3a3-b0cf-4d74-8e2a-d38f68b68639.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '112243',
        name: '折角插锁平粘底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/112243.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e304896f-ab71-4d71-8758-dc7fa2c64aa4.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110190',
        name: '撕拉线平粘盒',
        model_image: '//yun.baoxiaohe.com/render/20220414/21816490001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/75277bbb-1431-4307-b226-80efb8f23c13.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '108070',
        name: '插扣简易纸巾盒',
        model_image: '//yun.baoxiaohe.com/render/20220608/27433780001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/abd7855e-5375-464f-87b1-83d0af073efb.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '108060',
        name: '撕拉线插口纸巾盒',
        model_image: '//yun.baoxiaohe.com/render/20220916/32993930001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a6b08472-6d86-41b4-b07b-9bcbfd7bf86e.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109061',
        name: '异形撕拉纸巾盒',
        model_image: '//yun.baoxiaohe.com/render/20221025/35299010001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5f75eb8a-ab0e-4299-bc21-3c85e3a2b55e.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '700010',
        name: '纸盒1',
        model_image: '//yun.baoxiaohe.com/admin-materials/e1b31f71-c0be-4910-9105-13db86753ff8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d1cb321c-a5f2-477f-9d6a-ddc373cf7870.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '700014',
        name: '纸盒2',
        model_image: '//yun.baoxiaohe.com/admin-materials/8e1e7db3-6178-456a-8154-4353aa7e9c68.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/04dffd06-188b-4972-b281-447b8edff9d3.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '700011',
        name: '纸盒3',
        model_image: '//yun.baoxiaohe.com/admin-materials/84932bb0-4630-4dc9-8741-a8f61000e41e.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aa222f61-9af2-470a-9f60-ecabcba608cc.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '700013',
        name: '纸盒4',
        model_image: '//yun.baoxiaohe.com/admin-materials/8502fd47-fafc-435e-adfd-f9f2e0ca208d.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/c3e373ef-7697-4511-9c32-0876b147c80e.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '700012',
        name: '纸盒5',
        model_image: '//yun.baoxiaohe.com/admin-materials/a50a3875-8088-4a5b-bccc-fe3b014ec99d.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/66bb2bd3-911c-46e4-880c-13ef2838b8c8.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '109070',
        name: '展示盒类撕拉线机包盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1bb4426c-66b6-416c-a2c3-5353b23b18be.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/89d42c52-eeba-4565-aa07-d28ab3449804.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110171',
        name: '撕拉线平口盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/29f1c87d-3c57-4aca-8251-73a627b3d40d.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1bc4e5f9-2198-4def-bf27-80fef5f9dfe6.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '120710',
        name: '内衬平粘插锁盘式盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21182810001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9e12fc6f-90e2-4f0a-a896-27896ffaeb5c.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '116013',
        name: '屋顶盖粘底吊孔盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/11969b9a-15d4-466c-a532-86dc9752c028.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7ba4e950-0cee-453d-a27b-41b6fb2b1fb2.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '123210',
        name: '撕拉口盘式锁扣盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17973570001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a94044e1-42f8-4645-b96a-a52b31be286f.png',
        id: '70',
        sub_id: '77',
    },
    {
        model: '110018',
        name: '平粘底封套文件盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/40e2225c-b7a6-4556-9c3a-1e946b0ea808.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/804ddaea-5dd7-45c0-8fcb-7027df3105d0.png',
        id: '70',
        sub_id: '77',
    }
];