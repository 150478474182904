import React, { useEffect, useState, useRef } from 'react';
import groupBy from 'lodash/groupBy.js';
import forEach from 'lodash/forEach.js';
import { connect } from 'react-redux';

import { Field } from 'formik';
import { getQuantities } from '../../../../utils/productComponentUtil.js';
import { convertUnit } from '../../../../utils/converter.js';

// reactstrap components
import {
    Row,
    Col,
} from 'reactstrap';

import client from '../../../../feathers.js';
import axios from 'axios';
import Dropdown from '../../../Common/Dropdown.js';
import { cloneDeep } from 'lodash';

const PackagingPreviewByComponent = (props) => {
    const { role, values, userInfo, userId, productDynamicPriceId, productSuperDynamicPriceId, marginId, marginSuperId, setFieldValue, packageDetails } = props;
    const [ materials, setMaterials ] = useState();
    const [ packagings, setPackagings ] = useState();
    const [ selectedMaterial, setSelectedMaterial ] = useState();
    const [ printingSize, setPrintingSize ] = useState();
    const [ printingCost, setPrintingCost ] = useState([]);
    const [ quantity, setQuantity ] = useState();
    const [ additionalData, setAdditionalData ] = useState([]);
    const [ remainingComponents, setRemainingComponents ] = useState([]);
    const [ pricing, setPricing ] = useState([]);
    const [ loadingPreview, setLoadingPreview ] = useState(false);
    const [ enabledCostBreakdown, setEnabledCostBreakdown ] = useState(true);

    const timeoutRef = useRef(null);

    useEffect(() => {
        if (userInfo.package) {
            if (packageDetails?.moduleRestrictions?.costBreakdown) {
                setEnabledCostBreakdown(false);
            }
        }
    }, [userInfo, packageDetails]);

    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then(()=>{
                    const packagingComponentsIds = values?.packagingComponents?.map((x) => x.componentId);
                    const materialComponentsIds = values?.materialComponents.map((x) => x.componentId);
                    const printCostComponentsIds = values?.printCostComponents.map((x) => x.componentId);
                    const printSizeComponentsIds = values?.printSizeComponents.map((x) => x.componentId);
                    const quantityComponentsIds = values?.quantityComponents.map((x) => x.componentId);
                    const additionalComponentsIds = values?.additionalComponents.map((x) => x.componentId);
                    const componentIds = [
                        ...packagingComponentsIds,
                        ...materialComponentsIds,
                        ...printCostComponentsIds,
                        ...printSizeComponentsIds,
                        ...quantityComponentsIds,
                        ...additionalComponentsIds,
                    ];

                    return client.service('components').find({
                        query: {
                            _id: { $in: componentIds },
                            $limit: 1000,
                        }
                    });
                })
                .then((res)=>{
                    const packagingsData = res.data.filter((x) => x.componentTypeName === 'Packaging');
                    packagingsData.sort((a, b) => {
                        return values.packagingComponents.findIndex((a1) => a1._id === a._id) - values.packagingComponents.findIndex((b1) => b1._id === b._id);
                    });
                    setPackagings(packagingsData);
                    const materialsData = res.data.filter((x) => x.componentTypeName === 'Material');
                    materialsData.sort((a, b) => {
                        return values.materialComponents.findIndex((a1) => a1._id === a._id) - values.materialComponents.findIndex((b1) => b1._id === b._id);
                    });
                    setMaterials(materialsData);
                    setPrintingSize(res.data.find((x) => x.componentTypeName === 'Printing Size'));
                    const printCosts = res.data.filter((x) => x.componentTypeName === 'Printing Cost');
                    setPrintingCost(printCosts);
                    setQuantity(res.data.find((x) => x.componentTypeName === 'Quantity'));

                    const allRemaining = res.data.filter((x) => 
                        x.componentTypeName !== 'Packaging' &&
                        x.componentTypeName !== 'Material' &&
                        x.componentTypeName !== 'Printing Size' &&
                        x.componentTypeName !== 'Printing Cost' &&
                        x.componentTypeName !== 'Artwork Service' &&
                        x.componentTypeName !== 'Production Timeline' &&
                        x.componentTypeName !== 'Quantity'
                    );

                    const remaining = values?.additionalComponents.map((x) => {
                        return allRemaining.find((c) => c._id.toString() === x.componentId);
                    });

                    setRemainingComponents([...remaining]);

                    const data = materialsData.find((x) => x._id === values.preview.material);
                    setSelectedMaterial(data);

                    return {
                        remaining,
                        printCosts,
                    };
                })
                .then(async ({remaining, printCosts})=>{
                    const selectedMaterials = values?.materialComponents.find((x) => x.componentId === values.preview.material && x.nestedComponents.length > 0);

                    const nestedComponentIds = [];
                    if (selectedMaterials?.nestedComponents.length > 0) {
                        selectedMaterials.nestedComponents.map((y) => {
                            nestedComponentIds.push(y.componentId);
                        });
                    }
                    
                    values.preview.printCosts.map((x) => {
                        const pc = values?.printCostComponents.find((y) => y.componentId === x.split('~~')[0] && y.nestedComponents.length > 0);
                        if(pc) {
                            pc.nestedComponents.map((y) => {
                                nestedComponentIds.push(y.componentId);
                                return;
                            });
                        }
                        return;
                    });

                    const res = await client.service('components').find({
                        query: {
                            _id: { $in: nestedComponentIds },
                            $limit: 1000,
                        }
                    });

                    return {
                        res,
                        remaining,
                        printCosts,
                    };
                })
                .then(({res, remaining, printCosts})=>{
                    const selectedMaterials = values?.materialComponents.find((x) => x.componentId === values.preview.material && x.nestedComponents.length > 0);
                    const newRemainingComponents = [];
                    if (selectedMaterials) {
                        selectedMaterials.nestedComponents.map((x) => {
                            const nc = res.data.find((y) => x.componentId === y._id);
                            if (x.componentTypeName === 'Printing Size') {
                                setPrintingSize(nc);
                            } else if (x.componentTypeName === 'Printing Cost') {
                                const newPrintCostComponents = [nc, ...printCosts];
                                setPrintingCost([...newPrintCostComponents]);
                            } else if (x.componentTypeName === 'Quantity') {
                                setQuantity(nc);
                            } else {
                                newRemainingComponents.push(nc);
                            }
                        });
                    }

                    values.preview.printCosts.map((x) => {
                        const selectedPrintCost = values?.printCostComponents.find((y) => y.componentId === x.split('~~')[0]);
                        selectedPrintCost.nestedComponents.map((nc) => {
                            const ncData = res.data.find((y) => nc.componentId === y._id);
                            newRemainingComponents.push(ncData);
                        });
                    });

                    setRemainingComponents([...newRemainingComponents, ...remaining]);

                });
        }
    },[userId, values]);
    
    const updatePrice = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            axios({
                method: 'post',
                url: `${client.io.io.uri}changeProductSpecs`,
                data: {
                    ...values,
                    productData: {
                        productId: values._id,
                        ...values,
                    },
                    type: 'component',
                    preview: {
                        ...values.preview,
                        productionTimelineComponentId: values.productionComponents[0]?.componentId ?? '',
                        artworkId: values.artworkComponents[0]?.componentId ?? '',
                        productDynamicPriceId,
                        productSuperDynamicPriceId,
                        marginId,
                        marginSuperId
                    }
                },
                config: { headers: {'Content-Type': 'application/json' }}
            })
                .then((res) => {
                    setPricing(res.data);
                    setLoadingPreview(false);

                    if(res.data.additionalData) {
                        setAdditionalData([...res.data.additionalData]);
                    }
                })
                .catch((err) => {
                    setLoadingPreview(false);
                    console.log(err);
                });
        }, 500);
    };

    // on change preview
    useEffect(() => {
        if (values.preview.material) {
            setLoadingPreview(true);
            updatePrice();
            // Cleanup the previous timeout on re-render
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }
    }, [values]);


    const renderLoader = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            if (enabledCostBreakdown) {
                return value;
            } else {
                return <span className='xs font-italic'> Upgrade to view</span>;
            }
        }
    };

    const renderFinalPrice = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            return value;
        }
    };
    
    const renderRemainingComponents = (remainingComponents, pricing) => {
        
        return (
            <>
                {remainingComponents.map((x, i) => {
                    const price = pricing.pricingBreakdown?.optionPrices?.find((x) => x.index === i)?.price;
                    return (
                        <React.Fragment key={`${x.name}-${i}`}>
                            {/* Main Row */}
                            <Row key={`${x.name}-${i}`} className='m-0 px-4 py-2 w-100'>
                                <h5 className='d-flex align-items-center'>
                                    {x.name} - {renderLoader(price)}
                                </h5>
                                <Dropdown
                                    name={`preview.options[${i}]`}
                                    setFieldValue={setFieldValue}
                                    values={x.data.options?.map((o) => {
                                        return {
                                            value: `${x._id}~~${o._id}`,
                                            display: o.label
                                        };
                                    })}
                                    disabled={pricing?.metadata?.materialErrorMessage}
                                />
                            </Row>
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    const renderPrintCostComponents = (printCosts) => {
        const toRender = [];

        const groupedPrintCosts = groupBy(printCosts, 'name');
        forEach(groupedPrintCosts, (group, key) => {
            const options = [];
            group.forEach((x) => {
                x.data.printingCost.forEach((o) => {
                    options.push({
                        value: `${x._id}~~${o._id}`,
                        display: o.label
                    });
                });
            });

            toRender.push({
                name: key,
                options
            });
        });

        return (
            <>
                {toRender.map((x, i) => {
                    const price = pricing.pricingBreakdown?.printCostPrices?.find((x) => x.index === i)?.price;
                    return (
                        <Row key={`${x.name}`} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>
                                {x.name} - {renderLoader(price)}
                            </h5>
                            <Dropdown
                                name={`preview.printCosts[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.options?.map((o) => {
                                    return {
                                        value: o.value,
                                        display: o.display
                                    };
                                })}
                                disabled={pricing?.metadata?.materialErrorMessage}
                            />
                        </Row>
                    );
                })}
            </>
        );
    };

    return ( 
        <>
            <div className='preview-container bg-secondary rounded py-2 mb-3'>
                {loadingPreview && (
                    <div
                        className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 100 }}
                    >
                        <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                )}
                <Row className='m-0 px-4 pt-2 w-100'>
                    <h3 className='m-0 color-primary'>Price Calculator</h3>
                </Row>
                <Row className='m-0 px-4 py-2 w-100'>
                    <small>Preview the cost of your product here.</small>
                </Row>
                <Row className='m-0 px-4 py-2 w-100'>
                    <h5 className='d-flex align-items-center'>Packaging</h5>
                    {(packagings) && (
                        <Dropdown
                            name={'preview.packaging'}
                            setFieldValue={setFieldValue}
                            values={packagings?.map((x) => {
                                return {
                                    value: x._id,
                                    display: x.name
                                };
                            })}
                        />
                    )}
                </Row>
                {
                    (pricing?.length > 0 && pricing?.data?.find(x => x.componentType === 'Models')) &&
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5 className='d-flex align-items-center'>Models - {renderLoader(pricing.pricingBreakdown?.packagingPrice)}</h5>
                        <Dropdown
                            name={'preview.models'}
                            setFieldValue={setFieldValue}
                            values={pricing?.data?.find(x => x.componentType === 'Models')?.data?.map((x) => {
                                return {
                                    value: x,
                                    display: x
                                };
                            })}
                        />
                    </Row>
                }
                

                <Row className='m-0 px-4 py-2 w-100'>
                    <h5 className='d-flex align-items-center'>Material - {renderLoader(pricing.pricingBreakdown?.materialPrice)}</h5>
                    {(materials) && (
                        <Dropdown
                            name={'preview.material'}
                            setFieldValue={setFieldValue}
                            values={materials?.map((x) => {
                                return {
                                    value: x._id,
                                    display: x.name
                                };
                            })}
                        />
                    )}
                </Row>
                {(printingSize) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5 className='d-flex align-items-center'>Size - {renderLoader(pricing.pricingBreakdown?.sizePrice)}</h5>
                        <Dropdown
                            name={'preview.printingSize'}
                            setFieldValue={setFieldValue}
                            filtered={pricing?.data?.find(item =>  item?._id === printingSize?._id)?.filteredOutItems || []}
                            values={
                                [...printingSize.data.printingSizeDetails.sizes.map((x) => {
                                    return {
                                        value: `${printingSize._id}~~${x._id}`,
                                        display: x.label
                                    };
                                }),
                                (printingSize.data.printingSizeDetails.hasCustomSize) ? {
                                    value: `${printingSize._id}~~Custom`,
                                    display: 'Custom',
                                } : undefined
                                ]
                            }
                        />
                    </Row>
                )}
                {((values.preview.printingSize) && values.preview.printingSize.includes('Custom')) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <Col className='m-0 p-0 pr-1' md='4'>
                            <small>Length ({printingSize?.defaultSize ?? 'mm'})</small>
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                name={'preview.size.customLength'}
                            />
                            {/* <small className='text-danger xs'>Min: {pricing?.productData?.minLength}</small> */}
                            {/* <small className='text-danger xs'>Enter {pricing?.productData?.minLength} to {pricing?.productData?.maxLength} only</small> */}
                        </Col>
                        <Col className='m-0 p-0 pr-1' md='4'>
                            <small>Width ({printingSize?.defaultSize ?? 'mm'})</small>
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                name={'preview.size.customWidth'}
                            />
                            {/* <small className='text-danger xs'>Min: {pricing?.productData?.minWidth}</small> */}
                            {/* <small className='text-danger xs'>Enter {pricing?.productData?.minWidth} to {pricing?.productData?.maxWidth} only</small> */}
                        </Col>
                        <Col className='m-0 p-0 pl-1' md='4'>
                            <small>Height ({printingSize?.defaultSize ?? 'mm'})</small>
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                name={'preview.size.customHeight'}
                            />
                            {/* <small className='text-danger xs'>Min: {pricing?.productData?.minHeight}</small> */}
                            {/* <small className='text-danger xs'>Enter {pricing?.productData?.minHeight} to {pricing?.productData?.maxHeight} only</small> */}
                        </Col>
                        {(!!pricing?.metadata?.materialErrorMessage) && (
                            <Col className='m-0 p-0' md='12'>
                                <small className='text-danger xs'>{pricing?.metadata?.materialErrorMessage}</small>
                                {/* <small className='text-danger xs'>Enter {pricing?.productData?.minHeight} to {pricing?.productData?.maxHeight} only</small> */}
                            </Col>
                        )}
                    </Row>
                )}

                {renderPrintCostComponents(printingCost)}
                {renderRemainingComponents(remainingComponents, pricing)}
    
                {(quantity) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        {(quantity.data.quantity.isCustom) ? (
                            <>
                                <h5>Quantity</h5>
                                <Dropdown
                                    name={'preview.quantity'}
                                    setFieldValue={setFieldValue}
                                    values={getQuantities(quantity.data.quantity.isCustom, quantity)}
                                    disabled={pricing?.metadata?.materialErrorMessage}
                                />
                            </>
                        ) : (
                            <>
                                <h5>
                                    Quantity ({quantity.data.quantity.min} ~ {(quantity.data.quantity.isInfinite) ? '∞' : quantity.data.quantity.max})
                                </h5>
                                <Field 
                                    className='form-control form-control-sm form-control-alternative'
                                    type='number'
                                    onWheel={(e) => e.target.blur()}
                                    max={quantity.data.quantity.isInfinite ? 9999999 : quantity.data.quantity.max}
                                    min={quantity.data.quantity.min}
                                    name={'preview.quantity'}
                                    disabled={pricing?.metadata?.materialErrorMessage}
                                />
                            </>
                        )}
                    </Row>
                )}
    
                {additionalData.map((x, i) => {
                    return (
                        <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>{x.title} - {renderLoader(values.additionalDataPrices[i])}</h5>
                            <Dropdown
                                name={`additionalDataPrices[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data?.map((d) => {
                                    return {
                                        value: d.price,
                                        display: d.title
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(values.fileStorageComponents.length > 0) && (
                    <>
                        <Row className='m-0 px-4 py-2 w-100'>
                            <h5>File Upload</h5>
                            <Col md='12' className='d-flex justify-content-center p-3 m-0 rounded additional-info-container'>
                                <small>Upload files here</small>
                            </Col>
                        </Row>
                        
                    </>
                )}

                {(values.customFields.length > 0) && (
                    <>
                        {values.customFields.map((x, i) => {
                            return (
                                <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                                    <h5 className='d-flex align-items-center'>{x}</h5>
                                    <Field 
                                        className='form-control form-control-sm form-control-alternative'
                                        type='text'
                                        name={`customFieldsTemp[${i}]`}
                                    />
                                </Row>
                            );
                        })}
                    </>
                )}
    
                <Row className='mt-4 m-0 px-4 py-0 w-100'>
                    <h5 className='m-0 d-flex align-items-center'>Order Total</h5>
                </Row>
                <Row className='m-0 px-4 py-0 w-100'>
                    <h1 className='d-flex align-items-center color-primary'>{renderFinalPrice((Number(pricing.price ?? 0) + values.additionalDataPrices?.reduce((a, b) => Number(a) + Number(b), 0)).toFixed(2))}</h1>
                </Row>
            </div>

            
            <Row className='m-0 px-4 py-3 w-100 rounded additional-info-container'>
                <h3 className='d-flex align-items-center'>Additional Info</h3>
                <Row className='m-0 w-100'></Row>
                {(!!pricing?.metadata?.materialSize) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Material Size: {renderLoader(`${pricing?.metadata?.materialSize}`)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.totalWidth && !!pricing?.metadata?.totalHeight) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Open Size: {renderLoader(`${pricing?.metadata?.totalWidth}${printingSize?.defaultSize} x ${pricing?.metadata?.totalHeight}${printingSize?.defaultSize}`)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.yield) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Imposition per Sheet: {renderLoader(pricing.metadata?.yield)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.numOfSheet) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Total Sheets: {renderLoader(pricing.metadata?.numOfSheet)}</small>
                    </Row>
                )}
                {'-----------------------------'}
                {(!!pricing?.metadata?.packingSize) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Packing Volume: {renderLoader(pricing.metadata?.packingSize)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.packingWeight) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Packing Weight: {renderLoader(pricing.metadata?.packingWeight)}</small>
                    </Row>
                )}
                {'-----------------------------'}
                {(!!pricing?.metadata?.marginPercentage || !!pricing?.metadata?.superMarginPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Margin Percentage: +{renderLoader((((role === 'superadmin') ? pricing.metadata?.superMarginPercentage - 1 : pricing.metadata?.marginPercentage - 1) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.discountPercentage || !!pricing?.metadata?.superDiscountPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Dynamic Price Discount: -{renderLoader((((role === 'superadmin') ? 1 - pricing?.metadata?.superDiscountPercentage: 1 - pricing.metadata?.discountPercentage) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}

                {pricing?.metadata?.marginProfiles?.map((x) => {
                    if (x) {
                        return (
                            <Row key={x?.name} className='m-0 w-100'>
                                <small className='d-flex align-items-center'>Profile ({x.name}): {renderLoader(x.price)} (+{renderLoader(((x.percentage - 1) * 100)?.toFixed(2))}%)</small>
                            </Row>
                        );
                    } else {
                        return null;
                    }
                    
                })}
            </Row>
        </>
    );
};

const mapStateToProps = state => ({
    userInfo: state.role.details.user,
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagingPreviewByComponent);
