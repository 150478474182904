export const box78 = [
    {
        model: '150010',
        name: '飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150010.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/60a2d547-69c6-4334-9bf1-dea5abc84fdc.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '151031',
        name: '翻盖鞋盒01',
        model_image: '//yun.baoxiaohe.com/admin-materials/ea25ea61-7670-4599-ac67-8cba9ff8041f.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/5b70c1b2-c834-424f-aada-e89533df0841.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156215',
        name: ' 透气孔披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/9529feaf-39b2-46e8-b81a-3164cf7c3c23.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/af9102ac-e286-4009-b868-014cc9d7cea6.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156216',
        name: '指扣披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/4ba1d4ff-fec2-4f91-9693-fc89d390bed8.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6157a193-2a47-4af9-b019-c8af07e0a6df.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156217',
        name: '开窗透气孔披萨翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/e5684d9b-46a1-40f8-840b-132f197cb038.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7f2138f6-e3d4-4966-80c5-d8354420d897.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150011',
        name: '飞机盒V01',
        model_image: '//yun.baoxiaohe.com/render/20220113/150011.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6a4a2d61-cd77-4e10-990a-b81f31dfe52e.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150012',
        name: '指扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150012.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/20aed197-356b-4873-a16c-66906d3286df.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150013',
        name: '保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150013.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fe8b7ada-17bf-4fba-b415-978d81cffbb9.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150014',
        name: '双保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220126/17257220001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b5495840-12dc-4dcd-8232-1bf2c4389f31.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150015',
        name: '内衬飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150015.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0f99504c-64d7-40f4-9cfe-9dbd78bef082.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150020',
        name: '无插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150020.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/fe1641fb-f7b7-4add-a088-fe540a663606.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150030',
        name: '无侧翼飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150030.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/99ed326c-5cc5-4039-98d6-8e2dea8b5983.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150040',
        name: '翻盖飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150040.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f0a5f282-3f65-400f-a1cf-1c223e398576.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150160',
        name: '挂钩飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150160.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1a430664-6c50-4132-8875-5abe708b0e00.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150050',
        name: '开窗飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150050.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1c5c9d4d-7819-4cfb-9484-b3d48d93a52e.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150060',
        name: '开窗保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150060.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/6d0ef525-7919-42b7-8b07-78ea76ab9b50.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150090',
        name: '开窗双保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1a84cacc-7355-430a-9ded-5cbbd2ff4fba.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150031',
        name: '开窗无侧翼飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150031.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/9447ca8b-c30f-4fe4-a922-192e313d0c08.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150021',
        name: '开窗无插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150021.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3cc58816-8e89-4de7-aa39-eae17d7c6a24.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150041',
        name: '开窗翻盖飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150041.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/875e653c-535b-4b63-b8d4-d3e8c0417454.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150161',
        name: '开窗挂钩飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150161.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e20d0fd8-1026-4a12-a492-9879aab8f099.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156000',
        name: '异型飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156000.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3924435a-2a61-493f-b8ec-eab7b0ed946d.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150170',
        name: '矩形底座内衬飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150170.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d96f0a73-4c56-4d69-abfa-ec43108c66a3.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150032',
        name: '免孔无侧翼飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150032.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/dc9bc9f0-3d8e-49f6-ada4-4da40bd62b6b.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150230',
        name: '拉链飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150230.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/11d4874d-35a3-4542-a735-80f257359a3e.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150033',
        name: '内衬无侧翼飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150033.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/a08ca1f2-a63c-4e98-bdbb-e602be8b6f34.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156001',
        name: '内衬异型飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f5a4de44-9ecb-4d74-a8a2-d668fd89c2e1.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156080',
        name: '内衬插锁异型飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e47b7950-dbbd-47f5-a29e-8bd3a1c249cb.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156090',
        name: '拉链异型飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156090.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/89dea3c0-1082-4c2e-a914-f17385f357a4.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150110',
        name: '折角插锁飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150110.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/bdc1ee64-1e47-4b09-b495-a73d96277769.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150280',
        name: '双插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150280.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d12e3e41-9b7d-4d20-b914-3e75709a838d.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150300',
        name: '互锁扣卡纸飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150300.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2c84b08b-91b4-4ff5-bd7d-693d0db5fce0.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150301',
        name: '开窗互锁扣卡纸飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220121/17135920001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/21a8dbf2-31bf-43a2-980a-fc6ad9f76d7e.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156002',
        name: '异型插锁翻盖飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156002.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4425bf9d-544d-4f2a-bd5b-e3972e15422f.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150111',
        name: '插锁飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150111.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/0ba1c98d-1d0f-4196-94ff-b540ecc73c14.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150034',
        name: '无侧翼无痕底飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150034.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b3f1f946-cb80-4d78-b729-8f4b7c0d214d.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150035',
        name: '无痕底飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150035.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2ae0d38e-633d-4a71-b382-ca0320bc736d.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156003',
        name: '异型插锁无痕底盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/156003.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/66f4ce3e-fc17-43d8-8006-cc32c9e88947.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150330',
        name: '无痕底展示飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150330.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/4780bb78-e10e-45a8-af3f-0b60b5a2d91c.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156030',
        name: '异型侧页插扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220122/17150040001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e0e787f7-0bf4-4d64-b1c7-47ae530f3cd2.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150231',
        name: '拉链插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220222/17967680001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2a13a9f2-86eb-41f8-a8e9-8d03b5de565c.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150430',
        name: '侧边固定飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21204250001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/e184bde4-0231-40bd-b6df-45f733a6a020.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156050',
        name: '屋顶飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21217870001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aec8c3e5-db51-4a08-ab67-97a5d44e04fd.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150440',
        name: '固定内衬飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220408/21219990001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/f3ac67a6-d00f-4ca6-a1fd-b652318e0502.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150233',
        name: '内衬拉链飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/53b9d7a8-a49c-424b-bd87-4954dc138ffe.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/ae907629-1ba0-45ec-9201-1e10b3b631a3.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150112',
        name: '上盖插锁飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/1e8207bf-c27d-4b82-95e8-d11e63964520.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/d7019a40-752a-412d-8362-35606d556cbb.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '151032',
        name: '斜边翻盖鞋盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/3323a9e1-e0e4-498f-8ffe-b60d00c66f11.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/af0e71a0-e25d-4377-83a0-81d933ae8bca.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150022',
        name: '保险扣无插舌飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/08f053ab-eb5f-426a-b1d3-225b42916181.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/88ffb671-5786-4a4e-8d5c-2750bc13a52b.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '153032',
        name: ' 加厚型卡扣翻盖盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/8515d250-8e66-4570-bc35-5b224d2aa6dc.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/1c4930cf-1d79-40db-b9f8-1c2b888f6063.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150070',
        name: '开窗提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150070.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2bdae9d7-4724-4e09-ae6f-0d0d7f04ccc0.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150080',
        name: '开窗提手保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150080.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/aa7185c1-1380-4b0b-ba69-08290370cdd9.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150260',
        name: '提手双保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150260.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/995ac683-b1af-4e6c-aec1-34ab264a9bf1.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150162',
        name: '自带提手挂钩飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150162.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/52d0e1f7-d996-49c7-851c-073cf7256dd6.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156060',
        name: '双层飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20221024/35223470001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/7ea3d66d-869a-45fa-bb2b-eadb98281434.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150019',
        name: '上提手飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/bcd58455-c913-41f4-afa8-856568c020a6.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/321099db-8563-4f97-b31b-ce04960bf1e9.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150017',
        name: '提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150017.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/3c0df253-7f1f-4cff-a933-af4f1735c9dd.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150018',
        name: '提手保险扣飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150018.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b041c29c-ba4b-45c5-ac0c-0276a0151719.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150190',
        name: '手提绳飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220113/150190.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/b52c6905-2100-4958-a4e6-861456f2a0b0.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150016',
        name: '斜翼提手飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220915/32918610001.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/55698bae-bbb4-4596-b4f6-548cb0dc5999.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '150120',
        name: '封套飞机盒',
        model_image: '//yun.baoxiaohe.com/render/20220902/32325980001.jpg',
        knife_image: '//yun.baoxiaohe.com/admin-materials/2966f190-81fb-4fea-bcd6-a33d833ecb67.png',
        id: '70',
        sub_id: '78',
    },
    {
        model: '156004',
        name: '免开槽异型插锁飞机盒',
        model_image: '//yun.baoxiaohe.com/admin-materials/18b8f89a-d796-4753-a75f-6d23dad4962e.png',
        knife_image: '//yun.baoxiaohe.com/admin-materials/91960546-195c-411b-9027-ed9ed5b1dec6.png',
        id: '70',
        sub_id: '78',
    }
];